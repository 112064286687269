import { merge } from 'lodash-es';

import { externalUrls } from '@constants/urls.constants';
import { environment } from '@environment';
import { APP_NAMES, PROCESS_NAMES } from '@shared/constants/app-names.constants';
import { Application } from '@shared/models/application';
import { UserTypes } from '@shared/models/user';
import {
  informationHtml,
  policeRecordHtml,
  enrolmentChangeHtml,
  starDeclarationHtml,
  starEnrolmentChangeHtml,
  microCredentialsDeclarationHtml,
  cacHtml,
} from '@shared/partials/student-declaration';

import { QUALIFICATION_LEVELS } from './academic-history';
import { ACTION_LABELS } from './actions.constants';
import agentStrings from './agent-strings.constants';
import { internalUrls, deepInternalLinkWrapper } from './internalUrls';
import onlineStrings from './online-strings.constants';
import staffStrings from './staff-strings.constants';
import {
  CHANGE_ACTIONS,
  COURSE_STATE,
  APPLICATION_CHANGE_STATE,
  COURSE_STATUS,
  APPLICATION_STATE,
  OFFER_DECISION_STATE,
  MCED_QUAL_TYPES,
  COE_STATES,
} from './states.constants';

const html = String.raw;

const actionLabels = {
  save_and_submit: 'Complete',
  save_and_exit: 'Save and Exit',
  save_and_exit_always: 'Save and Exit',
  save_and_continue: 'Save and Continue',
  send_for_review: 'Send for Review',
  proceed_to_payment: 'Proceed to Payment',
  start_learning: 'Start Learning',
};

const numbersMap = [
  'zero',
  'one',
  'two',
  'three',
  'four',
  'five',
  'six',
  'seven',
  'eight',
  'nine',
  'ten',
  'eleven',
  'twelve',
  'thirteen',
  'fourteen',
  'fifteen',
  'sixteen',
  'seventeen',
  'eighteen',
  'nineteen',
  'twenty',
];

const ordinalMap = [
  'zeroth',
  'first',
  'second',
  'third',
  'fourth',
  'fifth',
  'sixth',
  'seventh',
  'eighth',
  'ninth',
  'tenth',
  'eleventh',
  'twelfth',
  'thirteenth',
  'fourteenth',
  'fifteenth',
  'sixteenth',
  'seventeenth',
  'eighteenth',
  'nineteenth',
  'twentieth',
];

const contact = {
  enrolmentEmailAddress: 'enrol@canterbury.ac.nz',
  admissionsEmailAddress: 'admission@canterbury.ac.nz',
  starEmailAddress: 'star@canterbury.ac.nz',
  scholarshipEmailAddress: 'scholarships@canterbury.ac.nz',
  iroEmailAddress: 'iro@canterbury.ac.nz',
  phone: {
    local: {
      display: '0800 VARSITY (827 748)',
      linkable: '0800827748',
    },
    international: {
      display: '+64 3 364 3443',
      linkable: '+6433643443',
    },
    iro: {
      display: '+64 3 369 2577',
      linkable: '+6433692577',
    },
    info: {
      display: '+64 3 369 3999',
      linkable: '+6433693999',
    },
  },
};

export interface IStatusModal {
  modalHeader: (year: number, courseOccurence?: string) => string;
  modalBody: (year: number, isImpersonating?: boolean, isInternational?: boolean) => string;
  acceptButtonText?: string;
  declineButtonText?: string;
}

const baseContactText = (email) => {
  return `
    email <a href="mailto:${email}">${email}</a> or phone <a href="tel:${contact.phone.local.linkable}">${contact.phone.local.display}</a>,
    international <a href="tel:${contact.phone.international.linkable}">${contact.phone.international.display}</a>
  `;
};

const baseIRO = () => {
  return `
    contact the Future Students Team on <a href="tel:${contact.phone.iro.linkable}">${contact.phone.iro.display}</a>, or email <a href="mailto:${contact.iroEmailAddress}">${contact.iroEmailAddress}</a>.`;
};

const contactCentre = baseContactText(contact.enrolmentEmailAddress);

const internationalRelationshipsOffice = baseIRO();

const needAssistance = `For assistance, ${contactCentre}`;

const needAssistanceForSubmitCourses = `To make course changes after your Enrolment Agreement has generated, `;

const endOfStageBase = {
  needAssistance: `<p>${needAssistance}</p>`,
  needAssistanceForSubmitCourses: `<p>${needAssistanceForSubmitCourses}<br>${contactCentre}</p>`,
  internationalInfo:
    '<p>If you are an International student we now have enough information to assess your application. Select <strong>Complete Stage One</strong> to submit your application and we will email you in due course.</p>',
  canMakeCourseChanges:
    '<p>You can make changes to your course selection and resubmit for assessment up until your Enrolment Agreement is available.</p>',
  incompleteStage: (type) => `
    <p>In order to continue your submission you need to ensure all tasks for this stage are 100 complete.</p>
    <p>Select Save and Exit if you are not ready to complete your ${type}. You can return to your ${type} when you are ready to complete it.</p>
  `,
  incompleteOfferDecision: (type) => `
  <p>In order to submit your offer ${type} you need to ensure all tasks for this stage are 100% complete.</p>
  <p>Select Save and Exit if you are not ready to make your ${type}. You can return to your offer ${type} when you are ready to complete it.</p>
  `,
};

let strings = {
  services: {
    condition: {
      notification: (year) => `More information is required for your ${year} application`,
    },
  },
  components: {
    atoms: {
      showMoreComponent: {
        showMore: 'Show more',
        showLess: 'Show less',
      },
      loginFooter: {
        header: APP_NAMES[UserTypes.student],
        copyright: '&copy; University of Canterbury.',
        redirectString: 'Return to the website.',
        redirectLink: externalUrls.ucHomepage,
      },
      goBackLink: {
        back: 'Go back',
      },
      crashOverlay: {
        error: {
          title: 'Beep boop! Error 500',
          body: `
            <p>We’re really sorry but it looks like we’ve encountered a problem.</p>
            <p>We may have lost some of the information on this page.</p>
            <p><strong>Please reload the page to try again.</strong></p>
            `,
          buttonText: 'Reload Page',
          imageKey: 'sadRobot',
        },
        outage: {
          title: 'Maintenance warning',
          body: `
            <p>The system will shortly be going down for maintenance.</p>
            <p>We will not be able to save any additional information while the system is down.</p>
            <p><strong>Please save your progress and try again later.</strong></p>
            `,
          buttonText: 'Close',
        },
        loggedUserCheck: {
          title: 'Multiple logins detected',
          body: `
            <p>We have detected that you have logged into myUC accounts in multiple tabs or windows so you cannot continue to use this user in this tab or window.</p>
            <p>Reload page to continue with the new user.</p>
            `,
          buttonText: 'Reload Page',
        },
        submitFeedback: 'Submit feedback',
        closeAndReload: 'Close and reload page',
      },
      jadeHydrateOverlay: {
        header: 'Loading, please wait.',
        body: 'Please wait while we load your previous enrolment information.',
        emphasizedText: 'This can take up to 30 seconds.',
      },
      disabledStateComponent: {
        whatIsThis: 'Why can’t I edit this?',
        modalMsg: `
        <p>
          Your details have been verified in our system. To change these details you need to provide us with a certified
          hardcopy of the relevant document either by post or in person to the Student Services Centre.
        </p>
        <p>
          <strong>Postal Address</strong><br>
          Admissions<br>
          University of Canterbury<br>
          Private Bag 4800<br>
          Christchurch 8140<br>
        </p>
        <p>If you have any queries regarding your application or any other enrolment items, please ${contactCentre}</p>
        `,
      },
      courseLevelPill: {
        level: 'level',
        noLevel: 'No level',
        postgrad: 'Postgraduate',
      },
      qualificationLink: {
        viewQualStructure: 'View qualification details',
      },
      toggleView: {
        display: 'Display:',
      },
      statusPill: {
        [Application.STATE.PROGRESS]: 'In progress',
        [Application.STATE.REVIEW]: 'In Review',
        [Application.STATE.INT_HOLD]: 'In Review',
        [Application.STATE.REJECTED]: 'UC Declined',
        [Application.STATE.SUBMITTED]: 'Submitted',
        [Application.STATE.UNREADY]: 'In progress',
        [Application.STATE.NOT_SUBMITTED]: 'In progress',
        [Application.STATE.DRAFT]: 'Draft',
        [Application.STATE.BEING_ASSESSED]: 'Being assessed',
        [Application.STATE.INCOMPLETE]: 'Incomplete application',
        [Application.STATE.CONDITIONAL_OFFER]: 'Conditional offer',
        [Application.STATE.OFFER_OF_PLACE]: 'Offer of Place',
        [Application.STATE.OFFER_OF_PLACE_ACCEPT_DECLINE]: 'Offer of Place',
        [Application.STATE.OFFER_ACCEPTED]: 'Offer accepted',
        [Application.STATE.OFFER_DECLINED]: 'Offer declined',
        [Application.STATE.OFFER_DEFERRED]: 'Offer deferred',
        [Application.STATE.AGREEMENT_AVAILABLE]: 'Agreement available',
        [Application.STATE.AGREEMENT_ACCEPTED]: 'Agreement accepted',
        [Application.STATE.AGREEMENT_DECLINED]: 'Agreement declined',
        [Application.STATE.FULLY_ENROLLED]: 'Fully enrolled',
        [Application.STATE.WITHDRAWN]: 'Withdrawn',
        [Application.STATE.DEFERRAL_REQUESTED]: 'Deferral requested',
        [Application.STATE.OFFER_IN_PROGRESS]: 'Offer decision in progress',
        [Application.STATE.DEFER_OFFER_IN_PROGRESS]: 'Deferral request in progress',
        [OFFER_DECISION_STATE.DRAFT_DEFER]: 'Deferral request started',
        [COE_STATES.SUBMITTED]: 'Change submitted',
        [COE_STATES.UNCERTAIN]: 'Change submitted',

        [COE_STATES.APPROVED]: 'Change in progress',
        [COE_STATES.IN_PROGRESS]: 'Change in progress',
        [COE_STATES.WITHDRAWN]: 'Change in progress',
        [COE_STATES.UNREADY]: 'Change in progress',
        [COE_STATES.COMPLETE]: 'Change in progress',
        [COE_STATES.DRAFT]: 'Change in progress',
        [COE_STATES.DELETED]: 'Change in progress',
        [COE_STATES.DECLINED]: 'Change in progress',
        [COE_STATES.FULLY_ENROLLED]: 'Change in progress',

        [APPLICATION_CHANGE_STATE.CHANGE_IN_PROGRESS]: 'Change in progress',
        [Application.STATE.NCEA_EMBARGO]: 'NCEA Embargo',
        courseStates: {
          [COURSE_STATE.CLOSED]: 'Courses not yet open',
          [COURSE_STATE.DRAFT]: 'Draft',
          [COURSE_STATE.SELECTED]: 'Courses selected',
          [COURSE_STATE.NOT_SELECTED]: 'Courses not selected',
        },
        resumeApplication: 'Resume Application',
        close: 'close',
        infoModal: {
          [Application.STATE.PROGRESS]: {
            modalHeader: (year) => `Enrolment for ${year}`,
            modalBody: () => `
            <p>You may have some outstanding questions or actions required to complete your enrolment.</p>
            <p>When you’re ready to complete the remaining items, you can resume your application from your Manage my study page.</p>
            <h3 class="modal-sub-title">International Students</h3>
            <p>If you have completed stage one of your application and chosen your qualification, your application is currently being assessed.</p>
            `,
          },

          [Application.STATE.REVIEW]: {
            modalHeader: (year) => `Enrolment for ${year}`,
            modalBody: () => `
            <p>Thank you for your application. We will review your application and contact you when this process is complete.</p>
            <p>You cannot do anything to your application until you are fully enrolled. You can view the details of your study from your Manage my study page.</p>
            `,
          },

          [Application.STATE.AGREEMENT_AVAILABLE]: {
            modalHeader: (year) => `Enrolment for ${year}`,
            modalBody: (year) => `
            <p>You have an Enrolment Agreement available for your study in ${year}.</p>
            <p>You cannot do anything to your application until you accept or decline your Enrolment Agreement. You can view your Enrolment Agreement or the details of your study from your Manage my study page.</p>
            `,
          },

          [Application.STATE.AGREEMENT_ACCEPTED]: {
            modalHeader: (year) => `Enrolment for ${year}`,
            modalBody: (year) => `
            <p>You’ve accepted your Enrolment Agreement for ${year}.</p>
            <p>Be aware that you are <b>not yet enrolled</b>. Once you’ve paid your fees, or provided evidence of an arranged payment, you will be fully enrolled in your courses.</p>
            <p>You cannot do anything to your application until you are fully enrolled. You can view the details of your study from your Manage my study page.</p>
            `,
          },

          [Application.STATE.FULLY_ENROLLED]: {
            modalHeader: (year) => `Enrolment for ${year}`,
            modalBody: (year) => `
            <p>You are fully enrolled for ${year}.</p>
            <p>To change your enrolment for the current academic year, select the Change enrolment button on your Manage my study page.</p>
            <p>You cannot change your enrolment for previous years.</p>
            `,
          },

          [Application.STATE.AGREEMENT_DECLINED]: {
            modalHeader: (year) => `Enrolment for ${year}`,
            modalBody: (year) => `
            <p>You have declined your Enrolment Agreement for study in ${year}.</p>
            <p>You can create a new application from your Manage my study page.</p>
            `,
          },

          [Application.STATE.INT_HOLD]: {
            modalHeader: (year) => `${year} International Hold`,
            modalBody: () => `
            <p>Thank you for your application. We’ll contact you when we’ve completed our review.</p>
            <p>In the meantime, select the Manage My Study button to provide additional application details.</p>
            <p>
            If you need to update or withdraw your application, please ${contactCentre}.
            </p>
            `,
          },

          [Application.STATE.SUBMITTED]: {
            modalHeader: (year) => `Enrolment for ${year}`,
            modalBody: (year) => `
            <p>Your enrolment for ${year} has been submitted. You are still able to withdraw this application or make changes to it.</p>
            <p>To withdraw this application or make changes to it, select one of the actions from your Manage my study page.</p>
            `,
          },

          [Application.STATE.WITHDRAWN]: {
            modalHeader: (year) => `Enrolment for ${year}`,
            modalBody: (year) => `
            <p>Your enrolment(s) are withdrawn for ${year}.</p>
            <p>You can create a new application from your Manage my study page.</p>
            `,
          },

          [Application.STATE.DRAFT]: {
            modalHeader: (year) => `Enrolment for ${year}`,
            modalBody: () => `
            <p>Are you ready to complete your application?</p>
            <p>You can resume your application from My Dashboard or the Manage my study page.</p>
            `,
          },

          [Application.STATE.BEING_ASSESSED]: {
            modalHeader: (year) => `Enrolment for ${year}`,
            modalBody: (year) => `
            <p>Your application for ${year} is currently being assessed.</p>
            <p>If you wish to make changes, select one of the actions from the Manage my study page.</p>
            `,
          },

          [Application.STATE.INCOMPLETE]: {
            modalHeader: (year) => `Enrolment for ${year}`,
            modalBody: (year, isImpersonating) => `
            <p>Your application is being assessed but we need more information.</p>
            <p>Check the information we require on the
            <a href="${deepInternalLinkWrapper(
              internalUrls.informationRequired(String(year)),
              isImpersonating,
            )}">Information Required page</a>.
            </p>
            `,
          },

          [Application.STATE.CONDITIONAL_OFFER]: {
            modalHeader: (year) => `Enrolment for ${year}`,
            modalBody: (year, isImpersonating) => `
            <p>Congratulations, you have a Conditional Offer of Place!</p>
            <p>View your offer and conditions within the
            <a href="${deepInternalLinkWrapper(internalUrls.documents, isImpersonating)}">Documents Library</a></p>
            `,
          },

          [Application.STATE.OFFER_OF_PLACE]: {
            modalHeader: (year) => `Enrolment for ${year}`,
            modalBody: (year, isImpersonating) => `
            <p>Congratulations, you have an Offer of Place!</p>
            <p>View your offer within the
            <a href="${deepInternalLinkWrapper(internalUrls.documents, isImpersonating)}">Documents Library</a></p>
            `,
          },

          [Application.STATE.OFFER_OF_PLACE_ACCEPT_DECLINE]: {
            modalHeader: (year) => `Enrolment for ${year}`,
            modalBody: (year, isImpersonating) => `
            <p>Congratulations, you have an Offer of Place!</p>
            <p>View your offer within the
            <a href="${deepInternalLinkWrapper(internalUrls.documents, isImpersonating)}">Documents Library</a></p>
            <p>To accept or decline your application, select Accept / Decline offer.</p>
            `,
            acceptButtonText: 'Accept / decline offer',
            declineButtonText: 'Cancel',
          },

          [Application.STATE.OFFER_ACCEPTED]: {
            modalHeader: (year) => `Enrolment for ${year}`,
            modalBody: (year, isImpersonating, isInternational) => {
              let outputText = `<p>Thanks for accepting your Offer of Place for ${year}.</p>`;
              outputText += isInternational
                ? '<p>Prepare for <a href="https://www.canterbury.ac.nz/study/getting-started/admission-and-enrolment/enrol-international-student" target="_blank">your arrival</a>.</p>'
                : '';
              return outputText;
            },
          },

          [Application.STATE.OFFER_DECLINED]: {
            modalHeader: (year) => `Enrolment for ${year}`,
            modalBody: (year) => `
            <p>You have declined your Offer of Place for study in ${year}.</p>
            <p>You can create a new application from the Manage my study page.</p>
            `,
          },

          [Application.STATE.OFFER_DEFERRED]: {
            modalHeader: (year) => `Enrolment for ${year}`,
            modalBody: (year) => `<p>Your enrolment(s) are deferred for ${year}.</p>`,
          },

          [Application.STATE.DEFERRAL_REQUESTED]: {
            modalHeader: (year) => `Enrolment for ${year}`,
            modalBody: () => '<p>Your request to defer is being considered. We’ll be in contact soon.</p>',
          },

          [COE_STATES.APPROVED]: {
            modalHeader: () => 'Change of Enrolment request in progress',
            modalBody: () => `
            <p>Your Change of Enrolment request will not be processed until you have completed all steps and submitted your request.</p>
            <p>When you’re ready to complete the remaining items, you can resume your Change of Enrolment request from your Manage my study page.</p>
            `,
          },
          [COE_STATES.IN_PROGRESS]: {
            modalHeader: () => 'Change of Enrolment request in progress',
            modalBody: () => `
            <p>Your Change of Enrolment request will not be processed until you have completed all steps and submitted your request.</p>
            <p>When you’re ready to complete the remaining items, you can resume your Change of Enrolment request from your Manage my study page.</p>
            `,
          },
          [COE_STATES.WITHDRAWN]: {
            modalHeader: () => 'Change of Enrolment request in progress',
            modalBody: () => `
            <p>Your Change of Enrolment request will not be processed until you have completed all steps and submitted your request.</p>
            <p>When you’re ready to complete the remaining items, you can resume your Change of Enrolment request from your Manage my study page.</p>
            `,
          },
          [COE_STATES.UNREADY]: {
            modalHeader: () => 'Change of Enrolment request in progress',
            modalBody: () => `
            <p>Your Change of Enrolment request will not be processed until you have completed all steps and submitted your request.</p>
            <p>When you’re ready to complete the remaining items, you can resume your Change of Enrolment request from your Manage my study page.</p>
            `,
          },
          [COE_STATES.COMPLETE]: {
            modalHeader: () => 'Change of Enrolment request in progress',
            modalBody: () => `
            <p>Your Change of Enrolment request will not be processed until you have completed all steps and submitted your request.</p>
            <p>When you’re ready to complete the remaining items, you can resume your Change of Enrolment request from your Manage my study page.</p>
            `,
          },
          [COE_STATES.DRAFT]: {
            modalHeader: () => 'Change of Enrolment request in progress',
            modalBody: () => `
            <p>Your Change of Enrolment request will not be processed until you have completed all steps and submitted your request.</p>
            <p>When you’re ready to complete the remaining items, you can resume your Change of Enrolment request from your Manage my study page.</p>
            `,
          },
          [COE_STATES.DELETED]: {
            modalHeader: () => 'Change of Enrolment request in progress',
            modalBody: () => `
            <p>Your Change of Enrolment request will not be processed until you have completed all steps and submitted your request.</p>
            <p>When you’re ready to complete the remaining items, you can resume your Change of Enrolment request from your Manage my study page.</p>
            `,
          },
          [COE_STATES.DECLINED]: {
            modalHeader: () => 'Change of Enrolment request in progress',
            modalBody: () => `
            <p>Your Change of Enrolment request will not be processed until you have completed all steps and submitted your request.</p>
            <p>When you’re ready to complete the remaining items, you can resume your Change of Enrolment request from your Manage my study page.</p>
            `,
          },
          [COE_STATES.FULLY_ENROLLED]: {
            modalHeader: () => 'Change of Enrolment request in progress',
            modalBody: () => `
            <p>Your Change of Enrolment request will not be processed until you have completed all steps and submitted your request.</p>
            <p>When you’re ready to complete the remaining items, you can resume your Change of Enrolment request from your Manage my study page.</p>
            `,
          },
          [COE_STATES.SUBMITTED]: {
            modalHeader: () => 'Change of Enrolment request submitted',
            modalBody: () =>
              '<p>Your Change of Enrolment request has been submitted. You can not make changes to this request.</p>',
          },
          [COE_STATES.UNCERTAIN]: {
            modalHeader: () => 'Change of Enrolment request submitted',
            modalBody: () =>
              '<p>Your Change of Enrolment request has been submitted. You can not make changes to this request.</p>',
          },
          [APPLICATION_CHANGE_STATE.CHANGE_IN_PROGRESS]: {
            modalHeader: () => 'Change of Application in progress',
            modalBody: () =>
              '<p>Your Change of Application will not be processed until you have completed all steps and submitted your change. When you’re ready to complete the remaining items, you can resume the change from your Manage my study page.</p>',
          },
          [Application.STATE.NCEA_EMBARGO]: {
            modalHeader: () => 'NCEA results release',
            modalBody: () => '<p>This application is currently under embargo due to NCEA results release.</p>',
          },
          courseModal: {
            [COURSE_STATE.DRAFT]: {
              modalHeader: () => 'Course selection status',
              modalBody: (year) =>
                `<p>You’ve started choosing courses for ${year} study. Make sure to finish your course selection and submit.</p>`,
            },
            [COURSE_STATE.SELECTED]: {
              modalHeader: () => 'Course selection status',
              modalBody: () =>
                '<p>Your application is complete. Your admission and course choices are now being assessed. We will be in contact soon!</p>',
            },
            [COURSE_STATE.NOT_SELECTED]: {
              modalHeader: () => 'Course selection status',
              modalBody: (year) => `<p>Thanks for your application – you can now choose your ${year} courses.</p>`,
            },
            [COURSE_STATE.CLOSED]: {
              modalHeader: () => 'Course selection status',
              modalBody: (year: number) =>
                `We’ve received your application for ${year}! Courses will open 1 October ${year - 1}.`,
            },
          },
        },
      },
    },
    molecules: {
      addressComponent: {
        address: 'Address',
        country: 'Country',
        addressLine1: 'Address (line 1)',
        addressLine2: 'Address (line 2)',
        town: 'City/Town',
        region: 'Province/Region',
        postcode: 'Postcode',
        attributions: `
          <ul>
            <li><a target="_blank" href="${externalUrls.googleTerms}">Google Terms and Conditions</a></li>
            <li><a target="_blank" href="${externalUrls.googlePriv}">Google Privacy Policy</a></li>
          </ul>
        `,
      },
      changeQualification: {
        changeQual:
          'If you need to make a change to your qualifications, select <strong>Change qualification / subjects</strong> below.',
        buttonText: 'Change qualification / subjects',
        header: 'Change Qualification or Subjects',
        body: 'By selecting this option you’ll be able to make changes to your qualification and/or subject selections.',
        ok: 'CHANGE QUALIFICATION',
      },
      changeofEnrolmentQualification: {
        changeGuidance: `<p>To keep your existing qualification(s) and change your subject options only, select Change Subject for the relevant qualification.</p>
                          <p> To add a new qualification, select Add Qualification.</p>
                          <p> If you do not wish to make qualification or subject changes, or you have made the required changes, select Save and Continue to go to Change Courses</p>`,
        changeSubject: 'Change subject',
        undoChange: 'Undo change',
        status: 'Status',
        changed: 'Changed',
        added: 'Added',
        coeHeader: 'Change study options',
        cancelChanges: 'Cancel',
        saveChanges: 'Save',
        addQualification: 'Add new qualification',
        addQualificationDescription: 'Add new qualification',
      },
      phoneSelector: {
        whatsMyCountryCode: "What's my country code?",
        mobileLabel: 'Mobile Number',
        mobileHint: 'Please enter a complete mobile phone number including country code e.g. +64 23 123 4567',
        landlineLabel: 'Landline Number',
        alternatePhoneLabel: 'Alternate Phone Number',
        landlineHint: 'Please enter a complete landline number including country code e.g. +64 9876 5432',
        alternatePhoneHint: 'Please enter an alternate contact number including country code e.g. +64 9876 5432',
        phoneNumberQuestion: 'Please provide at least one contact phone number',
      },
      footer: {
        generalEnquiry: 'General Enquiries',
        homepageLink: externalUrls.ucHomepage,
        infoPhoneLink: '+6433693999',
        infoPhone: '+64 3 369 3999',
        infoEmail: 'info@canterbury.ac.nz',
        enrolmentEnquiries: 'Enrolment Enquiries',
        enrolmentPhone: contact.phone.local.display,
        enrolmentPhoneLink: contact.phone.local.linkable,
        enrolEmail: contact.enrolmentEmailAddress,
        internationalEnquiries: 'International Enquiries',
        internationalEnquiriesPhone: contact.phone.international.display,
        internationalEnquiriesPhoneLink: contact.phone.international.linkable,
        askAQuestion: 'Ask a question',
        askAQuestionLink: externalUrls.askAQuestion,
        copyright: 'Copyright and Disclaimer',
        copyrightLink: externalUrls.ucCopyright,
        privacy: 'Privacy',
        privacyLink: externalUrls.ucPrivacyAndCopyright,
        cookies: 'Cookies Declaration',
        cookiesLink: externalUrls.ucCookies,
        emergencyInfo: 'Emergency Information',
        emergencyInfoLink: externalUrls.ucEmergency,
        needHelpText: 'Need help with your application?',
      },
      genderSelector: {
        genderLabel: 'Gender',
      },
      personalPronouns: {
        title: 'What are your personal pronouns?',
        bodyText: `This information helps us to support your journey as a student at UC.  You are under no obligation to share this with us
           and you can change the information at any time while you are a student. At UC we strive towards creating an environment that
           embraces and affirms all identities, which is part of
           <a href="${externalUrls.whatIsSpecialAboutUC}" target="_blank" rel="noopener">UC’s equity commitments</a>.`,
        customOption: 'Custom pronouns',
      },
      applicationYearListItemBaseStrings: {
        linkText: 'I want to withdraw my application',
        successMessage: 'Your application was successfully withdrawn',
        errorMessage: `An error has occurred. ${needAssistance}`,
        withdrawLink: 'Withdraw application',
        changeCoursesLink: 'Add / change courses',
        changeQualsLink: 'Change qualification / subjects',
        inactive: 'Inactive',
        showAll: 'Show all',
        courses: 'courses',
        qualification: 'qualification',
        qualificationOrSubjects: 'qualification or subjects',
        noActiveEnrolments: {
          header: 'No active enrolments',
          body: `
          You need to complete and submit a new application. Select <strong>Change qualifications / subjects</strong> to add a new qualification and submit your application.
          `,
        },
        withdrawApplication: {
          header: 'Withdraw Application',
          body: `
            <p>By selecting this option your application will no longer be available to amend or submit.</p>
            <p>Your personal details will still be available in myUC and you’ll be able to start a new application.</p>`,
          ok: 'WITHDRAW',
        },
        cannotWithdrawApplication: {
          header: 'Withdraw Application',
          body: `
            <p>You cannot withdraw your application at this time.</p>
            <p>For help with your application, please ${contactCentre}</p>`,
          ok: 'CLOSE',
        },
        withdrawApplicationProblem: {
          header: 'Withdraw Application',
          body: `
            <p>There was an error withdrawing your application.</p>
            <p>Please try again and if the error continues please ${contactCentre}</p>`,
          ok: 'CLOSE',
        },
        courseChange: (currentStage: number) => {
          const stage1Body =
            '<p>To add or change courses, you need to complete and submit your application. To complete your application, select Resume.</p>';
          const otherStageBody = '<p>To add or change courses, you may first need to complete other stages.</p>';
          return {
            header: 'Add or Change Courses',
            body: currentStage === 1 ? stage1Body : otherStageBody,
            ok: 'RESUME',
          };
        },
        qualChange: (inFinalStage: boolean) => {
          const finalStageBody =
            '<p>By selecting this option you’ll be able to make changes to your qualification and/or subject selections.</p>';
          const body =
            '<p>To change your qualification and/or subjects you need to complete and submit your application. To complete your application, select Resume.</p>';
          return {
            header: 'Change Qualification or Subjects',
            body: inFinalStage ? finalStageBody : body,
            ok: inFinalStage ? 'CHANGE QUALIFICATION' : 'RESUME',
          };
        },
        changeOfEnrolmentCourse: (isConcurrent = false) => {
          const body = `<p>Before you make changes to your qualification or courses, ensure you have read and understood the <a href="https://www.canterbury.ac.nz/enrol/change/" target="_blank">things to consider before changing your enrolment</a>.</p>
            <p>To add or change courses, select Add/Change courses.</p>`;
          const transfer =
            '<p>If you want to transfer courses between your two degrees, choose Transfer courses to make your changes.</p>';
          return {
            header: 'Add or change courses',
            body: isConcurrent ? body + transfer : body,
            ok: 'ADD OR CHANGE COURSES',
          };
        },
        changeOfEnrolment: {
          header: 'Change enrolment',
          body: `<p>Before you make changes to your qualification or courses, ensure you have read and understood the <a href="https://www.canterbury.ac.nz/enrol/change/" target="_blank">things to consider before changing your enrolment</a>.</p>
          <p>By selecting this option you will be able to make changes to your programme of study:</p>
          <ul>
          <li>Qualification changes - change subject options and/or add a qualification</li>
          <li>Course changes - add and/or drop courses</li>
        </ul>`,
          ok: 'CHANGE ENROLMENT',
        },
        declinedCourseUCSW: (year) => ({
          header: 'Add or change courses',
          body: `<p>You have declined your Enrolment Agreement for study in ${year}.</p>
          <p>To add or change courses, you need to complete and submit a new application. To start your application, select Resume.</p>`,
          ok: 'RESUME',
        }),
        declinedQualUCSW: (year) => ({
          header: 'Change Qualification or Subjects',
          body: `<p>You have declined your Enrolment Agreement for study in ${year}.</p>
          <p>To apply for another qualification, select Resume.</p>`,
          ok: 'RESUME',
        }),
        withdrawnCourseUCSW: (year) => ({
          header: 'Add or change courses',
          body: `<p>You have withdrawn your application for study in ${year}.</p>
          <p>To change your courses, you first need to submit your qualification. To add a qualification, select Resume.</p>`,
          ok: 'RESUME',
        }),
        withdrawnQualUCSW: (year) => ({
          header: 'Change Qualification or Subjects',
          body: `<p>You have withdrawn your application for study in ${year}.</p>
          <p>To apply for another qualification, select Resume.</p>`,
          ok: 'RESUME',
        }),
        changeNotAllowed: (field) => ({
          header: `Cannot change ${field}`,
          body: `<p>You can’t change your ${field} right now. For help with your Enrolment Agreement or application, please ${contactCentre}</p>`,
          ok: 'OK',
        }),
        changeNotAllowedOffer: (field) => ({
          header: `Cannot change ${field}`,
          body: `<p>You can’t change your ${field} right now because you have an Enrolment Agreement. You can download a copy from your myUC document library.</p>
          <p>For help with your Enrolment Agreement or application, please ${contactCentre}</p>`,
          ok: 'OK',
        }),
        changeNotAllowedDeferralRequested: (field) => ({
          header: `Cannot change ${field}`,
          body: `<p>You can’t change your qualification or subjects right now while we are processing your deferral request.</p>
          <p>If you need assistance, please ${contactCentre}</p>`,
          ok: 'OK',
        }),
        coeChangeNotAllowed: {
          header: 'Cannot change enrolment',
          body: `<p>You cannot change your enrolment as you already have a Change of Enrolment request that has not been completed.</p>
          <p>If the Change of Enrolment request is in progress you can delete it from your Manage my study page. If the Change of Enrolment request is Submitted you must wait for it to be completed.</p>`,
          ok: 'CLOSE',
        },
        coaChangeNotAllowed: {
          header: 'Cannot change Qualification or Subjects',
          body: `<p>You cannot change your qualification or subjects as you already have a change in progress.<p>
                 <p>You need to either delete or resume and complete that change before you can initiate another one.</p>`,
          ok: 'CLOSE',
        },
        qualNoStudentId: {
          header: 'Awaiting Student ID creation',
          body: `<p>We’re creating a Student Identification number for you.<p>
                 <p>You will receive an email confirming the ID number, once received you can continue with the application.</p>
                 <p>For assistance, ${contactCentre}</p>`,
          ok: 'CLOSE',
        },

        offerDecisionInProgress: {
          header: 'Offer decision in progress',
          body: `<p>Your offer decision will not be processed until you have completed all steps and submitted your decision.<p>
                 <p>Select <b>Resume</b> to complete and submit your decision.</p>`,
          ok: 'RESUME',
        },

        offerDecisionInProgressProblem: {
          header: 'Offer decision in progress',
          body: `<p>There was an error recording your offer decision.</p>
                 <p>Please try again and if the error continues please ${contactCentre}</p>`,
          ok: 'CLOSE',
        },
        nceaNotAllowed: (field) => ({
          header: `Cannot change ${field}`,
          body: `<p>This function is not available during the NCEA results release period.</p>
                 <p>Please try again later.</p>`,
          ok: 'OK',
        }),
        manageMyStudyButtonText: 'MANAGE MY STUDY',
        application: 'Application',
        showMoreInfo: 'Show more information',
        transfer: 'Transfer courses',
      },
      applicationTile: {
        applicationHeader: 'My Applications',
        appPara:
          '<p>Start a new application here. Select ‘Save and Continue’ at the end of each stage to save your progress.</p>',
        manageMyStudyButtonText: 'Manage my study',
        newApplicationButtonText: 'START NEW APPLICATION',
      },
      coursePlannerTile: {
        header: 'Explore Course Planner',
        coursePara:
          'UC has a diverse range of study options offering over 100 different qualifications in more than 80 different subject areas.',
        exploreNow: 'EXPLORE NOW',
      },
      addressSelector: {
        country: 'Country',
        addressLine1: 'Address (line 1)',
        addressLine2: 'Address (line 2)',
        town: 'City/Town',
        region: 'Province/Region',
        postcode: 'Postcode',
        attributions: `
          <ul>
            <li><a target="_blank" href="${externalUrls.googleTerms}">Google Terms and Conditions</a></li>
            <li><a target="_blank" href="${externalUrls.googlePriv}">Google Privacy Policy</a></li>
          </ul>
        `,
      },
      fullName: {
        firstnameLabel: 'Given name/first name',
        middlenameLabel: 'Middle name(s)',
        surnameLabel: 'Surname/family name',
        removeNameLabel: 'Remove this name',
      },
      studentLoan: {
        header: 'New Zealand Student Loan',
        description: `You may be eligible for a Student Loan if you’re a New Zealand citizen, have a Resident Visa, or have refugee or protected
          person status. Check the <a href="${externalUrls.studyLink}" target="_blank" rel="noopener">StudyLink</a> website to see
          if you’re eligible. If you intend to pay for your study with a Student Loan, ensure you apply via
          <a href="${externalUrls.studyLink}" target="_blank" rel="noopener">StudyLink</a> as soon as
          possible as your loan must be approved before you start your study.`,
      },
      ethnicityIwiSelector: {
        ethnicityQuestion: 'What is your ethnicity?',
        ethnicitySubtext: `<p>Ethnicity is the group or groups that you identify with or feel you belong to.
                            If your ethnicity doesn’t appear on the list, select ‘Other’.</p>
                            <p>We require this information for NZ government reporting purposes.</p>`,
        ethnicityLabel: 'Ethnicity',
        iwiQuestion: 'Select the Iwi/Rohe you identify with.',
        iwiSubtext: (ethNumber: number) => `Select up to ${ethNumber} iwi.`,
        iwiLabel: 'Iwi/Rohe',
        addEthnicity: 'Add Ethnicity',
        addIwi: 'Add Iwi/Rohe.',
        addEthnicities: (ethNumber: number) => `You can add up to ${ethNumber} ethnicities.`,
        ethnicityNumber: [
          'First Ethnicity',
          'Second Ethnicity',
          'Third Ethnicity',
          'Fourth Ethnicity',
          'Fifth Ethnicity',
          'Sixth Ethnicity',
        ],
        removeEthnicityText: 'Remove ethnicity',
      },
      unsavedModal: {
        header: 'Unsaved changes',
        question: 'Are you sure you wish to navigate away? Any unsaved changes will be lost.',
        saveText: 'SAVE AND CONTINUE',
        customButtonText: 'DISCARD CHANGES',
        closeText: 'Cancel',
      },
      contentSearchBar: {
        searchPlaceholder: 'Course title or code',
        search: 'Search',
        reset: 'Reset',
      },
      courseSummaryCard: {
        viewDetails: 'View course details',
        points: 'points',
        level: 'Level',
        courseAdded: 'Course added',
        selectedFor: 'Selected for',
        start: 'Start:',
        complete: 'Finish:',
        states: {
          [CHANGE_ACTIONS.ADDED]: 'Added',
          [CHANGE_ACTIONS.DROPPED]: 'Dropped',
        },
      },
      studyPlanSummaryBar: {
        courseSelection: 'My Course Selection',
        points: 'Total points for year:',
      },
      applicationHints: {
        stillToComplete: 'Still to complete on your application',
        showMe: 'Show me',
      },
      taskHints: {
        stillToComplete: 'Still to complete in this section...',
        questionsToComplete: (qty) => `You have ${qty} ${qty === 1 ? 'question' : 'questions'} to complete`,
        firstOne: 'Show me the first one',
      },
      courseView: {
        start: 'Start',
        complete: 'Finish',
        courseStates: {
          [COURSE_STATUS.APPROVED]: 'Approved',
          [COURSE_STATUS.DECLINED]: 'Declined',
          [COURSE_STATUS.BEING_ASSESSED]: 'Being assessed',
          [COURSE_STATUS.PENDING]: 'Being assessed',
          [COURSE_STATUS.WITHDRAWN]: 'Withdrawn',
          [COURSE_STATUS.FULLY_ENROLLED]: 'Fully enrolled',
          [COURSE_STATUS.WAITLISTED]: 'Waitlisted',
        },
        declineModal: {
          header: (courseOccurence) => `Admission to ${courseOccurence} is declined`,
          close: 'Close',
        },
        waitModal: {
          header: (courseOccurence) => `Waitlisted for course ${courseOccurence}`,
          close: 'Close',
          waitlistDescription: `<p>This course has a selection process, so it may be some time before you are approved.</p>
                                <p>If you have questions regarding your application, please reach out to a student advisor in your college/school.</p>`,
        },
      },
      courseSearchTableView: {
        columns: {
          courseCode: 'Course code',
          courseTitle: 'Course title',
          location: 'Location',
          level: 'Level',
          points: 'Points',
        },
      },
      accountDetails: {
        heading: 'Student account details',
        studentId: 'Student ID',
        firstName: 'First name',
        lastName: 'Last name',
        email: 'Permanent email address',
        usercode: 'Usercode',
        firebase: 'Firebase ID',
        canonical: 'Canonical ID',
        login: 'Login type',
        notApplicable: 'N/A',
      },
      copy: {
        copied: 'Copied',
      },
      coeDiff: {
        coursesAdded: 'Courses added',
        coursesRemoved: 'Courses removed',
        qualAdded: 'Qualification added',
        subjectChanged: 'Subjects changed',
        errorTitle: 'No courses added',
        errorDescription:
          'You have not added any courses to this qualification. Either remove the qualification or select Add/change courses to add a course.',
      },
      processInfoPanel: {
        deleteModals: {
          changeOfEnrolment: {
            header: 'Delete change of enrolment',
            body: 'By selecting this option, this change of enrolment request will not be assessed by the university and will not progress any further. You will not be able to undo this action.',
            ok: 'Delete change of enrolment',
          },
          changeOfApplication: {
            header: 'Delete change of application',
            body: 'By selecting this option, this change of application will not progress any further. We will continue to process your existing application.',
            ok: 'Delete change of application',
          },
        },
        resume: 'Resume',
        resumeChange: 'Resume change',
        cancelDecision: 'Cancel decision',
        coa: 'coa',
        deleteChange: (changeOfApplication) => {
          return changeOfApplication ? 'Delete change of application' : 'Delete change of enrolment';
        },
        statusText: {
          [COE_STATES.APPROVED]: {
            title: 'Change of Enrolment request in progress',
            body: `<p>Your Change of Enrolment request will not be processed until you have completed all steps and submitted your request.</p>
                   <p>Select <strong>Resume change</strong> to complete and submit your request.</p>`,
            delete: 'Delete change of enrolment',
            resume: 'Resume change',
          },
          [COE_STATES.IN_PROGRESS]: {
            title: 'Change of Enrolment request in progress',
            body: `<p>Your Change of Enrolment request will not be processed until you have completed all steps and submitted your request.</p>
                   <p>Select <strong>Resume change</strong> to complete and submit your request.</p>`,
            delete: 'Delete change of enrolment',
            resume: 'Resume change',
          },
          [COE_STATES.WITHDRAWN]: {
            title: 'Change of Enrolment request in progress',
            body: `<p>Your Change of Enrolment request will not be processed until you have completed all steps and submitted your request.</p>
                   <p>Select <strong>Resume change</strong> to complete and submit your request.</p>`,
            delete: 'Delete change of enrolment',
            resume: 'Resume change',
          },
          [COE_STATES.UNREADY]: {
            title: 'Change of Enrolment request in progress',
            body: `<p>Your Change of Enrolment request will not be processed until you have completed all steps and submitted your request.</p>
                   <p>Select <strong>Resume change</strong> to complete and submit your request.</p>`,
            delete: 'Delete change of enrolment',
            resume: 'Resume change',
          },
          [COE_STATES.COMPLETE]: {
            title: 'Change of Enrolment request in progress',
            body: `<p>Your Change of Enrolment request will not be processed until you have completed all steps and submitted your request.</p>
                   <p>Select <strong>Resume change</strong> to complete and submit your request.</p>`,
            delete: 'Delete change of enrolment',
            resume: 'Resume change',
          },
          [COE_STATES.DRAFT]: {
            title: 'Change of Enrolment request in progress',
            body: `<p>Your Change of Enrolment request will not be processed until you have completed all steps and submitted your request.</p>
                   <p>Select <strong>Resume change</strong> to complete and submit your request.</p>`,
            delete: 'Delete change of enrolment',
            resume: 'Resume change',
          },
          [COE_STATES.DELETED]: {
            title: 'Change of Enrolment request in progress',
            body: `<p>Your Change of Enrolment request will not be processed until you have completed all steps and submitted your request.</p>
                   <p>Select <strong>Resume change</strong> to complete and submit your request.</p>`,
            delete: 'Delete change of enrolment',
            resume: 'Resume change',
          },
          [COE_STATES.DECLINED]: {
            title: 'Change of Enrolment request in progress',
            body: `<p>Your Change of Enrolment request will not be processed until you have completed all steps and submitted your request.</p>
                   <p>Select <strong>Resume change</strong> to complete and submit your request.</p>`,
            delete: 'Delete change of enrolment',
            resume: 'Resume change',
          },
          [COE_STATES.FULLY_ENROLLED]: {
            title: 'Change of Enrolment request in progress',
            body: `<p>Your Change of Enrolment request will not be processed until you have completed all steps and submitted your request.</p>
                   <p>Select <strong>Resume change</strong> to complete and submit your request.</p>`,
            delete: 'Delete change of enrolment',
            resume: 'Resume change',
          },
          [COE_STATES.SUBMITTED]: {
            title: 'Change of Enrolment request submitted',
            body: `<p>Your Change of Enrolment request has been submitted. You can not make changes to this request.</p>
                   <p>If your Change of Enrolment request is approved you will receive a Confirmation of Change of Enrolment letter
                   which will confirm the course changes and any changes in tuition fees.</p>
                   <p><strong>If you have any queries regarding your request or you want to withdraw your request contact the Contact
                   Centre on <a href="tel:0800827748">0800 827 748</a> or <a href="tel:+6433643443">+64 3 364 3443</a></strong></p>`,
          },
          [COE_STATES.UNCERTAIN]: {
            title: 'Change of Enrolment request submitted',
            body: `<p>Your Change of Enrolment request has been submitted. You can not make changes to this request.</p>
                   <p>If your Change of Enrolment request is approved you will receive a Confirmation of Change of Enrolment letter
                   which will confirm the course changes and any changes in tuition fees.</p>
                   <p><strong>If you have any queries regarding your request or you want to withdraw your request contact the Contact
                   Centre on <a href="tel:0800827748">0800 827 748</a> or <a href="tel:+6433643443">+64 3 364 3443</a></strong></p>`,
          },
          [APPLICATION_CHANGE_STATE.CHANGE_IN_PROGRESS]: {
            title: 'Change of Application in progress',
            body: `<p>Your Change of Application will not be processed until you have completed all steps and submitted your request.</p>
                   <p>Select <strong>Resume change</strong> to complete and submit your change.</p>`,
            delete: 'Delete change of application',
            resume: 'Resume change',
          },
          [APPLICATION_STATE.OFFER_IN_PROGRESS]: {
            title: 'Offer decision in progress',
            body: `<p>Your Offer Decision will not be processed until you have completed all steps and submitted your decision.</p>
                   <p>Select <strong>Resume</strong> to complete and submit your decision.`,
            delete: 'Cancel decision',
            resume: 'Resume',
          },
          [OFFER_DECISION_STATE.DRAFT_DEFER]: {
            title: 'Deferral request started',
            body: `<p>Your Deferral request will not be processed until you have completed all steps and submitted your decision.</p>
                   <p> Select <strong> Resume deferral </strong> to complete and submit your request.</p>`,
            delete: 'Cancel deferral',
            resume: 'Resume deferral',
          },
          [OFFER_DECISION_STATE.SUBMITTED]: {
            title: 'Deferral request already in progress',
            body: `<p>Your Deferral request has been submitted. You can not make changes to this request.</p>
                   <p><strong>If you have any queries regarding your request or you want to withdraw your request contact the Contact
                   Centre on <a href="tel:0800827748">0800 827 748</a> or <a href="tel:+6433643443">+64 3 364 3443</a></strong>`,
          },
        },
      },
      fileList: {
        new: 'New',
      },
      multiSelect: {
        selectAll: 'Select All',
        clearAll: 'Clear All',
      },
    },
    organisms: {
      OnlineLearnerDetailsComponent: {
        title: 'UC Online',
        labels: {
          customerId: 'Stripe Customer ID',
          marketingEmailsOptIn: 'Opt in for marketing emails',
          azureB2cId: 'Azure B2C ID',
        },
      },
      conditionItem: {
        reasonQuestion: 'Why are we asking for this information?',
        nextStepsQuestion: 'What’s next?',
      },
      insuranceProvider: {
        header: 'Insurance Provider',
        description: `<p><strong>Studentsafe University Insurance</strong></p>
          <p>
            This is the University of Canterbury’s default insurance cover.
            If you arrive in New Zealand without insurance, you can purchase Studentsafe University Insurance
            cover during your enrolment in person at UC.
          </p>
          <p>
            Student insurance is a legal requirement under
            <a target="_blank" rel="noopener" href="${externalUrls.educationCodeOfPractice}">The Education (Pastoral Care of International Students) Code of Practice 2016</a>.
            For further information, please see <a target="_blank" rel="noopener" href="${externalUrls.ucIntInsuranceHelp}">Insurance for incoming students</a>.
          </p>
        `,
        insuranceSelector: {
          header:
            'Please identify which insurance provider you’ll be with when studying at the University of Canterbury',
          extraDescriptions: {
            // Studentsafe University Insurance
            '296': `
              Studentsafe University Insurance is the University’s current default insurance cover. It is compliant for studying in New Zealand.
              You can purchase Studentsafe University cover when you complete your enrolment in New Zealand.
              For further information, please see <a target="_blank" rel="noopener" href="${externalUrls.ucStudentsafeInsurance}">Insurance for incoming students</a>.`,
            // Other compliant insurance provider
            '998': `
              <span>
                Find out if your insurance plan is an approved insurance policy that meets the minimum requirements for international students.
                See <a target="_blank" rel="noopener" href="${externalUrls.ucApprovedIntInsurers}">Approved insurance policies</a>.
              </span>
              <br>
              <span>
                It is your responsibility to ensure your insurance covers the duration of your Student Visa.
              </span>
              `,
            // Other insurance cover (requires assessment)
            '999': 'It is your responsibility to ensure your insurance covers the duration of your Student Visa.',
          },
          otherCompliantProviderSelected: {
            header: 'Insurance Policy',
            description: `<p>Once you’ve organised your insurance, email a copy of your insurance policy and a
              Certificate of Insurance to <a href="mailto:student-insurance@canterbury.ac.nz">student-insurance@canterbury.ac.nz</a>.
              This certificate must show the start date and expiry date of the insurance.</p>`,
          },
          otherRequireAssessmentSelected: {
            description: `<p>You have selected <strong>Other insurance cover</strong> and will need to submit your insurance policy for an assessment by an external assessor.</p>
              <p>
                Please complete the
                <a target="_blank" rel="noopener" href="${externalUrls.ucIntInsuranceAssessment}">
                  Insurance Policy Assessment Application
                </a>
                and email this and a copy of your full insurance policy (in English) to: <a href="mailto:student-insurance@canterbury.ac.nz">student-insurance@canterbury.ac.nz</a>
                <strong>at least 4 weeks prior to enrolment</strong>.
              </p>
              <p>
                If you submit your application for <strong>assessment after this deadline</strong>, and a decision on your policy has not been made by the time you enrol,
                you’ll be required to purchase Studentsafe University Insurance. We’ll refund the Studentsafe premium paid if your policy is approved within 28 days of your
                course start date and no claims have been made.
              </p>`,
          },
        },
      },
      learningNeeds: {
        header: 'Learning Needs',
        primaryLanguage: 'What language do you and your family use most at home?',
        primaryLanguageOther: 'Please tell us what language you use most at home',
        declarationFirstInFamily: {
          question: 'Are you the first person in your immediate family to enrol in a university degree programme?',
          description:
            'Immediate family includes parents, brothers or sisters, and children. This is used for reporting purposes.',
        },
        declarationFamilyMemberGraduated: {
          question: 'Did your family member graduate?',
          description: `Immediate family includes parents, brothers or sisters and children. This is used for
              reporting purposes. If any of your family graduated, select ‘Yes’. If you don’t know or prefer not
              to answer, select ‘No’`,
        },
      },
      fileUpload: {
        helpText: `
        Please check that your files are:
        <ul>
          <li>Less than 10MB in size</li>
          <li>At least 300 dpi and a clear scanned image</li>
          <li>Documents with multiple pages combined into a single file</li>
          <li>Full colour (preferred)</li>
          <li>PDF (preferred), but we also accept .doc, .docx, .jpg and .png files</li>
        </ul>`,
        error: 'There was an error uploading your file, please read above.',
        otherError: 'There was an error uploading your file.',
        nameError: 'The file name may only use the characters A to Z, 0 to 9, and simple punctuation.',
        removeFileUploadError: 'Error removing your file. Please try again later',
        upload: 'Upload',
        uploading: 'Uploading',
        download: 'Download file.',
        add: 'Add another file',
        IDENTITY: 'Identity document',
        OFFER_LETTER: 'Enrolment Agreement',
        VISA: 'Residents visa',
        RESULTS_TERT: 'Tertiary results',
        RESULTS_SEC: 'Secondary results',
        TERT_GRAD_CERT: 'Tertiary graduation results',
        NAMECHANGE: 'Name change document',
        POLICE: 'Overseas police check report',
        ENGLISH: 'English language test certificate',
      },
      englishProficiencyDetail: {
        skillDescription: 'Which option best describes your English language skills?',
        countryQuestion: 'In which country did you complete this qualification?',
        teacherIelts: `If you are starting a Teacher education programme then the Teaching Council New Zealand
                      requires you to take an IELTS Academic English proficiency test.`,
        moreDetail: `Check our <a href="${externalUrls.ucInternationalEnglish}" target="_blank" rel="noopener">English language requirements</a>.`,
        tooltipContent: `Select ‘I have completed an academic qualification substantially taught in English in a country where
                        English is the predominant language’ option if you have a Secondary or Tertiary level qualification
                        from a country where English is the predominant language. Countries like this include but are not
                        limited to: Australia, Singapore, Kenya, South Africa, Great Britain, and the United States of America.`,
      },
      englishProficiencyTestType: {
        whichTest: 'Which English proficiency test?',
        otherLabel: 'Other',
        whenQuestion: 'When did you take the test?',
        listening: 'Listening',
        reading: 'Reading',
        writing: 'Writing',
        speaking: 'Speaking',
        overall: 'Overall',
        resultsQuestion: 'What were your results?',
        uploadResults: 'Upload a copy of your results.',
        whichTestWill: 'Which English proficiency test type will you sit?',
        whenScheduled: 'When is your English proficiency test scheduled?',
      },
      enrolmentSelector: {
        concurrentQual: 'Add a concurrent qualification (e.g. double degree).',
        concurrentQualExplain: 'Add another qualification to your selection above to study at the same time.',
        saveQual: 'Save Qualification',
        cancel: 'Cancel',
        choices: (num) => `${ordinalMap[num].toUpperCase()} CHOICE`,
        altChoiceTitle: 'Alternative choice',
        altChoiceGuidance: 'We’ll only consider this alternative choice if you are unsuccessful in your main choice.',
        altChoiceButtonTitle: 'Add an Alternative Choice Qualification',
        altChoiceButtonDescription: 'We’ll only consider this if you are unsuccessful in your main choice.',
      },
      profilePage: {
        profileTemplate: {
          myProfile: 'My Profile',
          mandatoryBanner: 'Mandatory fields are marked with',
          updateButton: 'Update Profile',
          emailLabel: 'Email Address',
          studentId: 'Student ID: ',
          studyEmail: 'Study email: ',
          usercode: 'Usercode: ',
          contactDetails: 'Contact Details',
          mobileLabel: 'Mobile number',
          mobilePlaceholder: '123456789',
          landlineLabel: 'Landline number',
          studyAddressHeader: 'Study Address',
          studyAddressQuestion: 'Complete this section if you know where you’re staying whilst studying at UC.',
          copyAddressButton: 'Copy Permanent Address',
          studyDetailsHeader: 'Study Details',
          homeAddressQuestion: 'What is your current address?',
          unsavedModal: {
            invalidFormQuestion: 'Are you sure you wish to navigate away? Any unsaved changes will be lost.',
            validFormQuestion:
              'You have unsaved changes on the page. Please complete all required information before saving.',
          },
        },
        permanentContactDetails: {
          sectionTitle: 'Permanent Contact Details',
          description: 'Words describing what we want for current contact details.',
          landlineLabel: 'Landline Number',
        },
        currentContactDetails: {
          sectionTitle: 'Current Contact Details',
          description: 'Words describing what we want for the current contact details.',
          emailLabel: 'Email',
          mobileLabel: 'Mobile Number',
          landlineLabel: 'Landline Number',
        },
        personalDetails: {
          sectionTitle: 'Personal Details',
          description: 'Words describing what we want for current personal details.',
          firstnameLabel: 'First Name',
          lastnameLabel: 'Last Name',
          fullnameLabel: 'Full Name',
          middlenameLabel: 'Middle Name',
          previousnameLabel: 'Previous Name/Family Name',
          preferrednameLabel: 'Preferred Name',
          preferrednameTooltip: `What first name do you want to be known as if different from above.
            We will use this name in correspondence with you.`,
          ethnicityLabel: 'Ethnicity',
          dateOfBirthQuestion: 'What is your birth date?',
          genderLabel: 'TODO: needs updating',
        },
        emergencyContactDetails: {
          sectionTitle: 'Emergency Contact Details',
          description: `<p>Please provide as much contact information as possible for your emergency contact person. We need at least one phone number.</p>
                        <p>UC will contact this person if we are concerned about your wellbeing and safety. Make sure this contact is an adult, knows you well and agrees to act as your emergency support person if required.</p>`,
          firstnameLabel: 'First Name',
          lastnameLabel: 'Last Name',
          emailLabel: 'Email',
          mobileLabel: 'Mobile Number',
          alternatePhoneLabel: 'Alternate Phone Number',
          relationshipLabel: 'Relationship to you',
          phoneNumberQuestion: 'Please provide at least one contact phone number',
          declaration: {
            description:
              'Please confirm that you have reviewed your emergency contact details and that all information provided is correct.',
            label: 'I confirm that I have reviewed my emergency contact details and they are correct',
          },
        },
      },
      legalName: {
        legalNameQuestion: 'What is your legal name e.g. the name on your passport?',
        legalNameTabHeading: 'Legal Name',
        preferredNameTooltip: `Select the preferred option against the name you are most commonly known as.
                               We can use this name in our correspondence with you and on class lists`,
        preferredNameLabel: 'This is my preferred name',
        otherNamesHeader: 'Other name(s)',
        otherNameTabHeadings: ['First Other Name', 'Second Other Name', 'Third Other Name'],
        addNameButtonHeading: 'Are you known by any other names e.g. maiden name or a preferred name?',
        addNameButtonText: 'Please add any other names additional to your legal name that you are known by.',
        documentQuestion: 'Are any of your documents in other name(s)?',
        documentQuestionDescription: `These documents could be a results record, your passport or other documents
                                      that you are asked to provide.`,
        uploadEvidence: 'Upload evidence of your other name(s).',
        uploadDescription: `Provide a copy of your deed poll, marriage, divorce certificate or other legal documents to assist
        us in being able to process your application.`,
      },
      loginForm: {
        createAccountPrompt: 'Don’t have an account? Create one',
        agenciesHeader: 'Agents',
        agentLogin: 'Access connectUC Portal',
        loginButton: 'STUDENT LOGIN',
        forgotQuestion: 'Forgot password?',
        confirmReset: {
          title: 'Reset Password Request',
          isUCAddress: `<p>You can reset your UC account password using the <a href="${externalUrls.ucPasswordReset}" target="_blank">Password Reset Tool</a></p>
                        <p>If you need assistance, contact the IT Service Desk on free call <a href="tel:0508824843">0508 UC IT HELP (0508 824 843)</a> or
                        <a href="tel:033695000">03 369 5000</a>.</p>`,
          success: `<p>We’ll check you have a myUC account and send you an email with instructions on how to reset your password.</p>
                    <p>If you don’t receive the reset email, check your spam folder.</p>
                    <p>If you need assistance, please ${contactCentre}</p>`,
        },
        resetPasswordForm: {
          title: 'Forgotten your password',
          body: '<p>Enter the email address or UC user name you use to login to myUC and select the Reset button.</p>',
          placeholder: 'Email Address or UC Username (current students only)',
          ok: 'Reset',
          cancel: 'Cancel',
          close: 'close',
          restrictedBody: `<p>To reset your password, you’ll need to get in touch with the team at UC.</p>
            <p>${contactCentre}</p>`,
        },
        contactCentre: `If you need assistance, please ${contactCentre}`,
        copyright: '© University of Canterbury.',
      },
      createAccountForm: {
        termsAndConditionsPrompt: 'I agree with the ',
        termsAndConditionsLinkText: 'Privacy Statement',
        createAccountButton: 'Create an account',
        legalNamePrompt: 'I have one legal name.',
        accountAlreadyExistsPrompt: 'Already have an account? Log in',
        noPasswordMatch: 'The passwords should match',
        duplicateEmail: [
          'Sorry that email is already registered. Please',
          'log in',
          'using that email or use another to create an account.',
        ],
      },
      socialLogin: {
        connect: 'Connect with',
        facebook: 'Facebook',
        google: 'Google',
      },
      documentLibrary: {
        name: 'Document Library',
        unreadDocuments: 'Important documents are available in the document library',
      },
      headerBar: {
        logout: 'Log out',
        notification: 'Notification Settings',
        account: 'Account Settings',
        profile: 'My Profile',
        impersonating: 'Impersonating: ',
        unimpersonate: 'Stop Impersonating',
        stop: 'stop',
        backToManageMyStudy: 'Back to Manage my study',
        backToDashboard: 'Back to dashboard',
        back: 'Back',
        home: 'Home',
        environment: 'environment',
        feedback: 'Feedback',
      },
      sidenav: {
        title: APP_NAMES[UserTypes.student],
        homepageLinkText: 'My Dashboard',
        profileLinkText: 'My Profile',
        scholarship: 'Apply For a Scholarship',
        manageMyStudyText: 'Manage my study',
        documentLinkText: 'Document Library',
        ucLinkText: 'UC Home',
        ucOnlineLinkText: 'UC Online',
        ucOnlineTotaraLinkText: 'Learning Platform',
        informationRequiredText: 'Information Required',
      },
      moreAboutYourStudies: {
        header: 'More about your studies',
        firstYearQuestion: (year) => `Will ${year} be your first year at the University of Canterbury?`,
        otherFirstYearQuestion: 'What was your first year at UC?',
        firstTertiaryYear: 'What year did you first start tertiary level study?',
        firstTertiaryYearSubText: 'This can be either in New Zealand or overseas.',
        firstTertiaryYearSubText2: (year) =>
          `If ${year} is your first year in tertiary study then please enter this as your answer.`,
        lastYearQ: (year: number) => `What were you doing (or do you expect to be doing) in October ${year - 1}?`,
      },
      coursePlannerHeader: {
        backToOverview: 'Back to overview',
        nextQual: 'Next qualification',
      },
      coursePlannerReviewPanel: {
        points: 'points',
        save: 'Save courses',
        infoMessage: (minPoints, maxPoints) => `The normal workload is between ${minPoints}pts and ${maxPoints}pts`,
      },
      coursePlanner: {
        searchResultCount: (resultCount: number) => `${resultCount} course${resultCount !== 1 ? 's ' : ' '}found`,
        noCoursesFound: 'No courses found',
        noCoursesFoundPara:
          '<strong>Tip:</strong> Change your search criteria, check your course code or spelling and try searching again',
        tooManyResults: 'Too many courses to display',
        tooManyResultsPara:
          '<strong>Tip:</strong> Narrow your results using the available search criteria, course code, title or keyword search',
        filterResults: 'Filter results',
        hideFilters: 'Hide filters',
        options: {
          pleaseSelect: '--- Please Select ---',
          allSubjects: 'All Subjects',
          allCourses: 'All Courses',
        },
        guidance: {
          searchHeader: '1. Search for courses',
          searchText: 'Search for courses by qualification, course code (e.g. MATH101), or by keyword',
          addHeader: '2. Add your courses',
          addText: 'Add a course to your selection by clicking on the red icon',
          reviewHeader: '3. Review your selection',
          reviewText: 'Review your selection to ensure you have chosen the correct courses and occurrences',
        },
        helpText: {
          needHelp: 'Need help?',
          helpHeader: 'Please contact us if you need assistance choosing courses or planning your programme of study:',
          helpInfo: `
            <li>For assistance choosing courses or planning your programme of study, please <a href="${externalUrls.academicAdvisors}" target="_blank">contact your Student Advisor</a></li>
            <li>To view what your timetable may look like, <a href="${externalUrls.timetablePlanner}" target="_blank">insert courses into the mock timetable planner</a></li>
          `,
          contactAssistance: `<p>For general assistance with your application or enrolment, please ${contactCentre}</p>`,
        },
        modal: {
          title: 'Course Error',
          defaultErrorMessage: 'An unknown error occurred.',
          assistanceText: `<p>${needAssistance}.</p>`,
          close: 'CLOSE',
          body: (code, message, action) => `
            <p>Could not ${action} course, ${code}. ${message}</p>
            <p>If you are having problems with your enrolment, please ${contactCentre}</p>
          `,
        },
      },
      enrolmentView: {
        concurrent: 'CONCURRENT',
        altChoice: 'ALTERNATIVE CHOICE',
        totalPoints: 'Total points for year: ',
        max500: 'Maximum 500 words.',
        points: 'points',
        selectedCourses: (qty) => `Selected Courses: ${qty}`,
        selectCourses: 'Select / view courses',
        addChangeCourses: 'Add / change courses',
        otherErrors: (pluralise: boolean) => `Other Error${pluralise ? 's' : ''}`,
        /* eslint-disable-next-line @typescript-eslint/naming-convention */
        enrolment_error: (pluralise: boolean) => `Enrolment Error${pluralise ? 's' : ''}`,
        /* eslint-disable-next-line @typescript-eslint/naming-convention */
        international_efts_warning: (pluralise: boolean) => `International Efts Warning${pluralise ? 's' : ''}`,
        /* eslint-disable-next-line @typescript-eslint/naming-convention */
        workload_error: (pluralise: boolean) => `Workload Error${pluralise ? 's' : ''}`,
        /* eslint-disable-next-line @typescript-eslint/naming-convention */
        enrolment_warning: (pluralise: boolean) => `Enrolment Warning${pluralise ? 's' : ''}`,
        exemptionReasons: 'Exemption reasons',
        exemptionReasonsDesc: `If you feel you have grounds to be permitted to take the above workload or courses, please provide your reasons below. You can also upload any supporting
        documentation using the Document Library.`,
        enrolmentCheckHeading: 'Enrolment checks',
        removeEnrolmentLabel: 'Remove this enrolment',
        enrolment: 'Enrolment',
        validationHelp: `Review the following enrolment checks, upload supporting documentation if applicable, and then <strong>Save and Continue</strong>.`,
        withdrawSuccessMessage: 'Your enrolment was successfully withdrawn',
        errorTitle: 'No courses added',
        errorDescription:
          'You have not added any courses to this qualification. Either remove the qualification or select Add/change courses to add a course.',
        validationMessages: {
          enrolment_warning: {
            title: 'Course selection advice',
            guidanceText:
              '<p>Prerequisite requirements for the following course(s) have not yet been met but you have enrolled to do so. Please note if you do not pass the prerequisite you may not be allowed entry to this course. No action is required.</p>',
          },
          enrolment_error: {
            title: 'Course selection warning',
            guidanceText: `<p>Apply for an exemption through the Exemption field below or amend your course selection.</p>
            <p><strong>NZ Secondary School students:</strong> Your course selection will be assessed when your NCEA results are released.</p>`,
          },
          workload_error: {
            title: 'Workload check',
            guidanceText:
              '<p>Apply for an excess workload in the Exemption field below, or reduce your number of courses.</p>',
          },
          international_efts_warning: {
            title: 'International student workload check',
            guidanceText: null,
          },
          withdraw_warnings: {
            title: 'Course withdrawal warning',
            guidanceText: '<p>You are outside the final date to add or withdraw with a full refund.</p>',
          },
        },
        withdrawModal: {
          header: 'Withdraw Application',
          body: `<p>By selecting this option, this application will not be assessed by the university and will not progress any further. You will not be able to undo this action.</p>
                 <p>Your personal details will still be available in myUC and you will be able to start a new application.</p>`,
          ok: 'Withdraw',
        },
        withdrawErrorModal: {
          header: 'Withdraw Unsuccessful',
          body: `We are unable to action your request. For assistance please ${contactCentre}`,
          ok: 'Close',
        },
        inactiveCourses: 'Inactive course selections',
        courseEmbargoWarning: 'Courses cannot be viewed during the NCEA results release period.',
      },
      categoryFileUpload: {
        sectionHeader: 'Document Upload',
        uploadButtonHeading: 'Upload a document',
        uploadButtonText: "To upload a document, select 'Upload a document' and select the correct categories",
        documentCategory: 'Document category',
        applicationYear: 'Application year',
        uploadFile: 'Upload',
        uploading: 'Uploading',
        uploadedDocuments: 'Uploaded documents',
        successfulUpload: 'Document uploaded successfully!',
      },
      searchFilters: {
        show: 'Show',
        defaultLabels: {
          level: 'All levels',
          teachingPeriodCode: 'All teaching periods',
          site: 'All locations',
        },
      },
      secondaryEducation: {
        description: [
          'The Ministry of Education requires us to collect this information.',
          "If you're still completing this qualification, tell us what you expect to achieve and when you expect to achieve it.",
        ],
        nsnLabel: 'Do you know your National Student Number (NSN)?',
        nsnDescription: `This is a National Student Number or New Zealand Qualifications Authority (NZQA) number given to those who have previously studied in New Zealand.
          Your NSN is usually a 9 digit number. Do not include any zeroes at the start of the number.`,
        highSchoolInNewZealand: (qualNum) => {
          return qualNum === 0
            ? 'Did you attend secondary/high school in New Zealand?'
            : 'Was this secondary/high school in New Zealand?';
        },
        qualLabel: ['First Qualification', 'Second Qualification'],
        NCEA3MessageBanner:
          'If you are currently studying NCEA Level 3 please change your answer above to NCEA Level 3. Otherwise, answer the questions below for your NCEA Level 2 qualification.',
        highestSecondaryQual: 'What is your highest secondary/high school qualification?',
        additionalSecondaryQual: 'Secondary/high school qualification',
        yearCompletedQualification:
          'What year did you complete this qualification, or will you complete this qualification?',
        uploadResults: 'Upload a copy of your secondary/high school results.',
        uploadResultsTitle: 'Secondary results',
        uploadResultsHelp: [
          'Upload your secondary school results. This could be a results notice or academic record from the authority administering the qualification.',
          'CIE and IB students are required to upload predicted or AS or O Level results if final results are not yet available.',
        ],
        lastYearAttended: 'What year did you last attend secondary/high school?',
        wasXLastYearAttended: (year) => `Was ${year} your last year of secondary/high school?`,
        whichSchoolAttended: 'Which secondary/high school were you attending when you got this qualification?',
        waitingForResults: 'Are you still waiting for your results?',
        qualification: 'Qualification',
        otherQualification: 'Qualification name',
        otherCountry: 'In which country did you complete this qualification or expect to complete this qualification?',
        addQualQuestion: 'Do you have another secondary/high school qualification to tell us about?',
        addQualButtonLabel: 'Add qualification',
        addQualButtonText:
          'Use this section to tell us about any secondary/high school study in another country.<br>If you’ve told us about NCEA Level 3 you don’t need to add NCEA Level 1 or 2.',
        studiedOutsideNZGuidance:
          'Please select No to the ‘Did you attend secondary/high school in New Zealand?’ question above if you studied this qualification overseas.',
        schoolNotOnList: 'Secondary/high school name',
      },
      tertiaryEducation: {
        tertiaryTitle: 'Tertiary / University Education',
        foundationTitle: 'Foundation Education',
        description:
          "If you're still completing this qualification, tell us what you expect to achieve and when you expect to achieve it.",
        qualLabel: (index) => {
          const ordinal = ordinalMap[index + 1];
          const formatedOrdinal = ordinal.slice(0, 1).toUpperCase() + ordinal.slice(1);
          return `${formatedOrdinal} qualification`;
        },
        addQualQuestion: 'Do you have other tertiary level study to tell us about?',
        addQualButtonLabel: 'Add qualification',
        gpaReport: 'Upload a copy of your Scholaro GPA report.',
        gpaReportHelp: `As a direct applicant to UC, with an international tertiary qualification, you are first required to have your GPA calculated by <a href="${externalUrls.gpaReport}" target="_blank">Scholaro</a> using their premium version (US $9.99).
          <strong>Please note that this is mandatory</strong> unless you are:
          <ul>
            <li>applying for the MBA or PGDipBA,</li>
            <li>a recipient of a Manaaki New Zealand Scholarship</li>
            <li>applying through the CANTEACH programme, or</li>
            <li>currently completing your qualification.</li>
          </ul>`,
        uploadGpaReportLabel: 'Scholaro GPA report',
        uploadResults: 'Upload a copy of all of your results.',
        uploadResultsHelp:
          'Upload all of your transcript(s) of your previous study. This may be called an academic transcript, or results notice. If you are still studying, provide evidence of this. Eg partial transcript, letter from your institution, CV for MBA, MEM, PGDipBA.',
        uploadResultsLabel: 'Results',
        uploadGraduationCertificate: 'Upload evidence of completion.',
        uploadGraduationCertificateHelp:
          'This may include your graduation certificate or other evidence of completion.',
        uploadGraduationCertificateLabel: 'Evidence of completion',
        foundationQuestion: 'What is the name of the qualification or transfer programme you have completed?',
        foundationGuidance:
          'Do not complete this section if you are applying for one of these programmes. Go back and change your highest level of study to the qualification that you already have or are currently enrolled in.',
        foundationQual: 'Qualification or transfer programme',
        programmeOrCourse: 'What is the name of the programme or course?',
        educationalProvider: 'Educational provider',
        startYear: 'What year did you start this programme or study?',
        startYearQual: 'What year did you start this qualification?',
        haveYouCompleted: 'Have you completed this study?',
        qualName: 'What is the name of this qualification?',
        completeYear: 'What year did you complete this study?',
        haveYouCompletedQual: 'Have you completed this qualification?',
        completeQualYear: 'What year did you complete this qualification?',
        country: 'Country',
        beenExcluded: 'Have you ever been excluded from another tertiary education provider?',
        beenExcludedGuidance:
          'Excluded means that you have had your enrolment cancelled or have been prevented from re-enrolling at a university for academic or disciplinary reasons.',
        transferCredit:
          'Do you want to apply to transfer credit for courses taken at another tertiary education provider?',
        transferGuidance: `<p>It is a separate application process to apply for credit.<br>
          See <a href="${externalUrls.ucTransferOfCredit}" target="_blank" rel="noopener">Transfer credits</a> for instructions.</p>`,
        europeanDiplomaTitle: 'European Diploma Supplement',
        europeanDiplomaDescription:
          'Please provide us with a copy of your European Diploma Supplement if your tertiary education was completed in a European country.',
        europeanDiplomaQuestion: 'European Diploma Supplement',
      },
      identityGroups: {
        title: 'Identity groups',
        bodyText: `This information helps us to support your journey as a student at UC. You are under no obligation to share this with us and
          you can change the information at any time while you are a student. At UC we strive towards creating an environment that embraces
          and affirms all identities, which is part of
          <a href="${externalUrls.whatIsSpecialAboutUC}" target="_blank" rel="noopener">UC’s equity commitments</a>.`,
        tellUsAboutYourself: {
          title: 'Tell us about yourself',
          bodyText:
            'Ticking the boxes that apply to you allows staff to proactively support you on your learning journey. The information is only shared with relevant staff that need it to provide support to you or for UC’s equity monitoring work.',
          doYouIdentify: 'Do you identify with any of the following identity groups?',
          groups: {
            rainbow: 'Rainbow (LGBTQIA+ / Takatāpui) community',
            refugee: 'Refugee background',
            religionFaith: 'Religion or faith',
          },
          selectAnOption: 'Select an option',
          enterAnswer: 'Enter answer',
        },
      },
    },
    template: {
      authAction: {
        continue: 'Continue',
        password: {
          title: 'Reset your password',
          newPassword: 'New password',
          confirmPassword: 'Confirm password',
          success: {
            title: 'Password changed',
            body: 'You can now sign in with your new password.',
          },
          failure: {
            title: 'Try resetting your password again',
            body: 'Your request to reset your password has expired or the link has already been used.',
          },
        },
        verifyEmail: {
          success: {
            title: 'Your email has been verified',
            body: 'You can now sign in with your new account.',
          },
          failure: {
            title: 'Try verifying your email again',
            body: 'Your request to verify your email has expired or the link has already been used.',
          },
        },
        recoverEmail: {
          success: {
            title: 'Updated email address',
            body: (
              email: string,
            ) => `Your sign-in email address has been changed back to ${email}. If you didn’t ask to change your sign-in email,
                  it’s possible someone is trying to access your account and you should change your password right away. We’ve
                  sent you a password reset email, please check your inbox.`,
          },
          failure: {
            title: 'Unable to update your email address',
            body: `There was a problem changing your sign-in email back. If you try again and still can’t reset your email, please ${contactCentre}.`,
          },
        },
      },
      accountSettings: {
        header: 'Account Settings',
        mandatoryBanner: 'Mandatory fields are marked with',
        changeEmail: {
          header: 'Change myUC account email address',
          info: 'You should receive an email to verify your updated email address. Click the link in the email to finalise the update process.',
          sectionHeader: 'Change myUC account email address',
          sectionInfo: `<p>Change your myUC account email address. You can also choose to update some of your other email addresses associated
                        with your profile as well.</p>`,
          disabled: '<p>Email address change is currently disabled</p>',
          emailLabel: 'New myUC login email',
          currentEmail: 'Current myUC login email',
          passwordInfo: 'Enter your password below to confirm you are the account holder.',
          passwordLabel: 'Password',
          changeButton: 'Change email address',
          infoBanner: `<p>A verification email will be sent to your new email address. Please follow the verification link in the email to complete the change.
                        Your password will remain the same.</p>
                       <p>Once you send this request and have verified your new email address, you’ll be logged out and will be able to log in with
                        your new address. Your password will remain the same.</p>`,
          emailAddressLabel: (emailAdress, type: string) => `${emailAdress} - ${type[0].toUpperCase()}${type.slice(1)}`,
        },
        changePassword: {
          header: 'Change myUC account password',
          existingPassword: 'Current myUC password',
          newPassword: 'New myUC password',
          confirmPassword: 'Confirm new myUC password',
          updatePassword: 'Change password',
          successMessage: 'Your password was successfully updated!',
        },
      },
      applicantSplashPage: {
        header: 'Start your enrolment journey here.',
      },
      pageNotFoundTemplate: {
        notFound: 'Not found!',
        backToDash: 'Back to the dashboard',
        messageText: `<p>We’re really sorry but we can’t find the page you are looking for.</p>
          <p><strong>Try again or go back to the dashboard.</strong></p>`,
      },
      emailVerification: {
        title: 'Confirm Your Email',
        body: `
          <p>
            We’ve sent you an email as part of creating your account.
            Please click the link in the email to confirm your email address.
          </p>
          <p>If you didn’t receive an email, click the <strong>RESEND EMAIL</strong> button below to send another one.</p>
          <p>Once you’ve confirmed your email, you can close this window and start your application process.</p>`,
        chinaBody: `
          <p>We’ve sent you an email as part of creating your account.</p>
          <p>Once you’ve confirmed your email, you can close this window and start your application process.</p>
          <p>If you haven’t received an email from us within an hour, please ${contactCentre}</p>`,
        ok: 'Resend email',
        cancel: 'Close',
      },
      dashboard: {
        applicantUpdate: 'Your profile was updated successfully',
        enrolmentReviewSubmitError: 'Submission failed, please try again later',
        enrolmentInfo: 'Enrolment Information',
        enrolmentInfoLink: externalUrls.ucAdmissionAndEnrolment,
        codeOfConduct: 'Student Code of Conduct',
        codeOfConductLink: externalUrls.ucCodeOfConduct,
        healthCentre: 'UC Health Centre',
        healthCentreLink: externalUrls.ucHealthCenter,
        CWYN: 'Check what you need',
        CWYNLink: externalUrls.ucCWYN,
      },
      documentPage: {
        goBack: 'Go back',
        header: 'Document Library',
        description: `
          <p>Documents uploaded as part of the application process will be displayed below. You can upload additional documents to support your application.</p>
        `,
      },
      processPage: {
        missing: (task) => `The ${task} task is not currently implemented.`,
        previousTask: 'Previous Step',
        cancelTask: 'Cancel',
        actionLabel: (labelKey, stageNum, processCode, stageCount) => {
          if (labelKey === ACTION_LABELS.SAVE_AND_SUBMIT) {
            const processCodeMap = {
              [PROCESS_NAMES.COE]: 'Submit Change of Enrolment',
              [PROCESS_NAMES.COA]: 'Submit Change of Application',
              [PROCESS_NAMES.DEFER_OFFER]: 'Save and Submit',
              [PROCESS_NAMES.OFFER_DECISION]: 'Save and Submit',
            };

            if (processCodeMap[processCode]) {
              return processCodeMap[processCode];
            } else if (stageNum === stageCount) {
              if (actionLabels[labelKey] === 'Complete' && stageCount === 3) {
                return `Submit Courses`;
              }
              return `${actionLabels[labelKey]} application`;
            } else {
              return `${actionLabels[labelKey]} stage ${numbersMap[stageNum]}`;
            }
          }
          return actionLabels[labelKey];
        },
        endModalTitle: 'Incomplete Stage',
        endModalContent: `In order to continue your submission you need to ensure all tasks for this stage are 100% complete. Click
            Continue to complete your submission, or Go to Dashboard.`,
        agentReminder: '<p><strong>Reminder:</strong> Enter the student’s details in this application form.',
        // eslint-disable-next-line complexity
        flashMessage: (stageIndex, processCode, isInternational) => {
          const newStudent = processCode === PROCESS_NAMES.NEW_STUDENT;
          if (stageIndex === 0 && newStudent && isInternational) {
            return 'Your application has been successfully submitted!';
          } else if (stageIndex === 2) {
            return 'Your enrolment has been submitted for review.';
          }
        },
      },
      ssoTemplate: {
        redirecting: 'Redirecting...',
        emailForm: {
          title: 'Missing Details',
          body: 'We don’t have an email address recorded for you. Please supply a valid email address to access scholarship information.',
          placeholder: 'abc123@uclive.ac.nz',
          ok: 'OK',
          cancel: 'Cancel',
        },
        invalidEmailForm: {
          title: 'Invalid Email',
          body: 'Please supply a valid email address.',
        },
      },
      manageMyStudy: {
        title: 'Manage my study',
        description: 'Update your current applications.',
        newApplicationButtonText: 'START NEW APPLICATION',
        noActiveApplications: "You don't have any applications yet.",
        goToDashboard: 'Back to my dashboard',
      },
      informationRequiredPage: {
        header: 'Information Required',
        documentsSectionHeader: 'Documents to provide',
        description:
          'To progress your application please provide the following information. Your application will be on hold until this information is provided.',
        noInfoNeededMessage: (year) => `You have no information to provide for your ${year} application at this time`,
        actionRequiredHeader: 'Action Required',
      },
    },
    tasks: {
      reviewDetails: {
        citizenshipSection: {
          howToChange: `If you wish to change your citizenship or residency category, you must provide us with
          evidence such as a residence class visa, passport or citizenship certificate. Certified copies can be
          posted to Enrolments, Student Services, or delivered in person to the Student Services Centre.`,
        },
        identityLabel: 'Identity document',
        permanentAddressHeader: 'Permanent Address',
        studyAddressHeader: 'Study Address',
        studyAddressQuestion: 'Complete this section if you know where you’re staying whilst studying at UC.',
        copyAddressButton: 'Copy Permanent Address',
        learningNeedsHeader: 'Learning Needs',
      },
      usingMyUc: {
        processHelpButtonText: 'Application to enrol user guide',
        subheader: {
          header: 'Important things you need to know',
          description:
            'myUC is designed to speed up your application to enrol. The following guide shows you how to use myUC to complete your application.',
        },
        navigation: {
          header: 'Easy to use navigation',
          description:
            'On the left side of your screen is the application navigation. This consists of stages, and tasks within those stages. You can select a task from the list to open that task.',
        },
        pausing: {
          header: 'Pause your application',
          buttonText: actionLabels.save_and_continue,
          description: `
            <p>We know there’s a lot to organise when completing your application. That’s why you can save at any point and return later.</p>
            <p><strong>If you don’t have all the information to complete your current task, select the save and continue button to complete other tasks within the application stage.</strong></p>`,
        },
        timeout: {
          header: '60 minute timeout',
          description:
            'If you leave your application idle without saving for more than 60 minutes, you will lose any information you have entered since your last save.',
        },
        validation: {
          header: 'Entering the right information',
          description: 'Check you have successfully completed each field',
          empty: 'This field is not yet completed',
          success: 'This field is successfully completed',
          error: 'This field contains invalid content',
        },
        mandatoryFields: {
          header: 'Mandatory fields',
          description: `<p>The <span class="required-asterisk">*</span> symbol identifies fields you must complete to successfully submit your application.</p>
            <p><strong>Note: You can skip past these fields while completing the tasks but you will need to go back and complete them before submitting your application.</strong></p>`,
        },
        withdrawing: {
          header: 'Withdrawing your enrolment',
          description:
            'If you need to withdraw an enrolment, look for the trash can icon or the withdraw button on your Manage my study page. One of these options will be available depending on the progress of your enrolment.',
        },
        moreInformation: {
          header: 'View more information about your study',
          description:
            'For more information about your applications and to see what actions are available for an application, visit your Manage my study page by clicking the link on your My Applications tile.',
        },
        footer: {
          header: 'Ready to start?',
          description: `<p>That’s it! If you need any assistance, contact the Contact Centre on <a href="tel:0800827748">0800 VARSITY (827 748)</a>,
          international <a href="tel:+6433643443">+64 3 364 3443</a>, or email <a href="mailto:enrol@canterbury.ac.nz">enrol@canterbury.ac.nz</a></p>
          <p>Select the <strong>OK</strong> button below to continue.</p>`,
        },
        processValidation: {
          header: 'Incomplete application',
          description:
            '<p>If you miss any mandatory questions you’ll see a hint like this to help you find the incomplete questions.</p>',
        },
      },
      internationalStudentInsurance: {
        description: `To enrol at the University of Canterbury (UC), international students
          (including international PhD students) <strong> MUST </strong> have appropriate
          and current medical and travel insurance while studying in New Zealand.`,
      },
      applicationToEnrol: {
        usingMyUc: {
          header: 'Using myUC application to enrol',
          description:
            'To view a guide that shows you how to use myUC to complete your application, select the <strong>Application to enrol user guide</strong> button below.',
          accentedCharacterWarning: {
            header: 'Accented character use (ā, ë)',
            description:
              'Unfortunately, we are unable to accept accented characters on this form. We are working towards inclusion of these characters.',
          },
        },
        studyIntentions: {
          header: 'Start Date',
          question: 'When do you intend to begin your studies?',
          studyDescription: 'Most New Zealand qualifications begin in February (start of the academic year).',
          dateQuestion: 'Proposed start date',
          descriptions: {
            'Semester 2': `If you’re not sure whether the qualification you’re applying for has a July start,
              contact the ${contactCentre}`,
            'Summer school': `If you’re not sure what date this is, please ${contactCentre}`,
            Thesis:
              'If you’re enrolling in a thesis course, you may be able to start your thesis at any time during the year.',
            Block: `Block courses start on different dates. If you’re not sure about the date you start, please ${contactCentre}`,
            Other: `Some qualifications and courses start on different dates. If you’re not sure about the date you start, please ${contactCentre}`,
          },
        },
        yearSection: {
          header: 'Year of Application',
          question: 'What year are you applying for?',
          yearLabel: 'Application Year',
        },
        identificationDetailsSection: {
          header: 'Identification Details',
          description: [
            `Enter your legal details as stated on your identification document such as passport,
            birth certificate or citizenship certificate.`,
            'We are also asking for other names that you might have used to help us identify you.',
          ],
          birthDateQuestion: 'What is your birth date?',
        },
        citizenshipSection: {
          header: 'Citizenship and Residency',
          categoryQuestion: 'What is your citizenship or residency category?',
          categoryLabel: 'Citizenship Category',
          countryQuestion: 'What is your country of citizenship?',
          countryLabel: 'Citizenship Country',
          liveInNzQuestion: 'Will you be living in New Zealand during your study?',
          liveInNzDescription: `Answer ‘yes’ if you’ll be spending most of your time in New Zealand, with only short
            overseas vacations. Answer ‘no’ if you’ll be spending most of your time out of New Zealand.`,
          ministryRuling: 'NZ Ministry of Education ruling:',
          rulingDesc: `NZ Ministry of Education classifies you as an <strong>International fee-paying</strong> student if you’re not
                      residing in NZ during your study and you’re either:
                      <ul>
                        <li>A permanent NZ resident </li>
                        <li>An Australian citizen, or </li>
                        <li>A permanent Australian resident. </li>
                      </ul>`,
          internationalRulingDesc: `If you intend to spend most of your time out of New Zealand you may not be eligible for the programme you choose.
            To confirm your eligibility, please ${contactCentre}`,
          passportInfo: `Upload a copy of the information page from your current passport, birth certificate or
                        citizenship certificate.`,
          birthCertificate: 'If you do not have a passport then please upload a copy of your birth certificate.',
          residentsVisa: 'Upload a copy of your residents visa.',
          starGuidance: `<p>Once you are enrolled you need to either send a verified copy of your citizenship document certified by your Principal or a JP in the mail to us, or bring in the original so we can sight it.
            Grades will not be released until we have verified the citizenship status.</p>
            <p>For more information see the STAR <a href="${externalUrls.star}" target="_blank" rel="noopener">How to Apply</a> tab on the UC website.</p>`,
          mcedGuidance: `Please note that while you are supplying an electronic version in this application, you may be required to provide a certified copy in person
            due to name changes, your secondary qualification, etc. More information on this is available <a href="${externalUrls.ucDocumentCertification}" target="_blank">here</a>`,
        },
        contactDetailsSection: {
          header: 'Contact Details',
          description: 'How can we contact you regarding your application?',
          emailSubsectionQuestion: 'What is your email address?',
          emailLabel: 'Email',
          mobilePlaceholder: '12 345 6789',
          landlinePlaceholder: '9 8765432',
          addressQuestion: 'What is your current address?',
        },
        agentDetails: {
          header: 'Agent or Study Abroad Details',
          applyingViaAgent: 'Are you applying via an Agent or Study Abroad provider/programme?',
          agencyLabel: 'Agency or Study Abroad provider/programme',
          agencyDescription:
            'This is the name of the company your agent works for or your Study Abroad provider/programme in your home country.',
          agentLabel: 'Agent or Study Abroad coordinator ',
          agentDescription: 'This is the name of the person you deal with.',
          emailLabel: 'Agent or Study Abroad coordinator contact email',
          emailTooltip: `This is the email you use to contact your agent. It may be the email address
                        of your agent or the agency they work for.`,
        },
      },
      universityAdmission: {
        formTitle: 'University Entrance',
        header: 'University Admission',
        description: `NZ law requires applicants to be of a certain standard  before they can study at a NZ University.
                      There are a number of ways to gain admission to a NZ University and this section assists us in determining
                        if you meet the University admission standard. Your eligibility is based on your qualifications,
                      results, and English language proficiency.`,
        ueQuestion: `Before you start to study will you have, or expect to have, NZ University Entrance through any of the following
                      qualifications or programmes?`,
        whenCompleteLabel: 'What year did you, or do you expect to, achieve this qualification?',
      },
      teachingQualification: {
        sectionTitle: (qualName) => `For your ${qualName} qualification, select where and how you’ll study.`,
        howLabel: 'How will you study?',
        whereLabel: 'Where will you study?',
      },
      caCompliance: {
        cv: {
          title: 'Curriculum Vitae (CV)',
          description: [
            'Please provide us with a copy of your CV',
            'Your CV (also known as a resume or biodata document) tells us important information about you including any previous experience, education, or qualifications.',
            'Include any experience working with children and young adults, leadership roles, and community group involvement over the last 5 years. Note that these can be paid, voluntary or work experience roles.',
            'Also include any other relevant qualifications such as first aid certificate, coaching qualification, Duke of Edinburgh Award or similar certificate or school achievements.',
          ],
        },
        confidentialRefereeReport: {
          title: 'Confidential referee reports',
          description:
            'You must nominate referees to complete and submit a confidential referee report. Please ensure your nominated referees have agreed to act as a referee for you and are aware of which programme you are applying for. We will contact your referees directly via email with the relevant information on how to complete the online referee report.',
          refereeNumber: ['First referee report', 'Second referee report', 'Third referee report'],
          tooltip: `
            <div>
              <p>
                Suitable people to complete your reports could be:
              </p>
              <ul>
                <li>an employer (recommended).</li>
                <li>a colleague</li>
                <li>a tutor/ lecturer</li>
                <li>a religious or community leader</li>
              </ul>
              <br>
              <p>The people that cannot be a referee are immediate family, friends and flatmates.</p>
              <br>
              <p>Your referees must be different from the person
              completing your confidential school report if you need one</p>
            </div>`,
        },
        caAdditionalInformation: {
          title: 'Children’s Act Additional Information',
          description:
            'The following information is required as part of our obligations under the Children’s Act 2014.',
          hasChildRemoved: 'Have you had a child removed from your care?',
          childRemoved: '',
          hasDisciplinaryProceeding:
            'Have you been the subject of a professional disciplinary or misconduct proceeding?',
          hasInvestigationProtectionOrder:
            'Have you been the subject of an investigation by a child protective service or had a protection order issued against you?',
          hasDeniedEntry: 'Have you been denied entry to, or been suspended or excluded from, a similar programme? ',
          childRemovedGuidanceText: 'Please provide details',
        },
        associatedPerson: {
          name: 'Referee name',
          relationship: 'Role/relationship',
          email: 'Email address',
        },
      },
      offerDecision: {
        title: 'Offer of Place Decision',
        description: 'Almost there! You have been provided a full Offer of Place at UC for the following:',
        guidanceText: 'Accepting this Offer of Place is a step required to securing your place at UC.',
        acceptText: 'Yes. I accept this Offer of Place, including start date.',
        declineText: 'No. I decline this Offer of Place',
        warningText: `Note: if you want to change your study start date, please go back to your dashboard and select the Request deferral action.`,
        warningTextDoctoral: `Note: if you want to change your study start date, please go back to your dashboard and select the Request deferral action.<br><br>
        You will need to provide evidence of supervisor support for your deferred start date.`,
        studyStart: 'Study start:',
        accept: {
          title: 'Confirm acceptance',
          description: `<p>I accept that it is my responsibility to have enough funding to cover both tuition fees and living costs for the duration of my studies. To work out the cost of living in New Zealand, please see
                        <a href="${externalUrls.costOfLiving}" target="_blank" rel="noopener">Cost of living</a>.</p>`,
        },
        decline: {
          title: 'Decline offer',
          description: 'Please tell us your reason for declining this Offer of Place from UC.',
          warningMsg: 'Note: Declining this Offer of Place will withdraw your application.',
          declineReasonLabel: 'Decline reason',
          otherReason: 'Other decline reason',
        },
        declarationAgree: 'I agree to the above declaration:',
        declarationLabel: 'Funding acceptance information',
      },
      deferOffer: {
        currentQuals: 'This is your current qualification selection:',
        deferGuidance: 'Complete this request if you need to move your study start date to a future date.',
        deferTitle: 'Defer offer',
        deferDateGuidanceDoctoral:
          'If requesting a change in start date please discuss this with your provisional supervisor first. The start date for your doctoral qualification will be the first day of a month, but 1 January and 1 December are normally not recommended as start dates.',
        deferDateGuidance: 'If requesting a change in start date please discuss this with your faculty first.',
        deferralYear: 'Deferral year',
        deferralDate: 'Deferral date',
        noQualOccsBanner: `It looks like there are no occurrences available. Please contact us using AskUC Live Chat; ${contactCentre}`,
        uploadEvidenceTitle: 'Supervisor approval to defer',
        uploadEvidenceGuidance:
          'Please provide us with evidence that your thesis supervisor approves your request to defer your start date.',
        uploadLabel: 'Upload your evidence here',
        international: 'International',
        domestic: 'Domestic',
      },
      policeCheck: {
        recordDeclaration: 'Police record declaration - New Zealand and Overseas',
        declarationDescription: [
          'The Children’s Act 2014 requires us to Police check any student who may come into contact with children during their study.',
          'You must declare if you’ve been convicted of any crime (excluding minor traffic, but including drink driving offences) in NZ or any other country.',
          'If in any doubt - declare it.',
        ],
        policeRecord: {
          pending: 'Do you have any criminal charges pending trial?',
          convicted: 'Have you ever been convicted of any criminal offence against the law?',
          detailCharges: 'Detail these charges.',
          detailConvictions: 'Detail each of these convictions.',
          excludeMinor: 'Exclude minor traffic offences but include drink driving offences.',
          includeFullDesc: 'Include a full description and dates of all convictions',
          professional: 'Professional or otherwise.',
          detailMisconduct: 'Detail any misconduct.',
        },
        nzCheck: {
          header: 'New Zealand Police check',
          description: [
            'We require a New Zealand Police Check for all applicants to programmes of study that require Children’s Act compliance regardless of whether or not you’ve resided in New Zealand.',
            'We’ll organise this on your behalf if we invite you to an interview.',
          ],
        },
        identityValidation: {
          header: 'Identity validation information',
          description: 'The following information is required to conduct a New Zealand Police check.',
          questions: ["NZ Driver's license number", 'NZ Passport number', 'City of birth', 'Country of birth'],
          driversLicenseHint: 'Please add a valid licence number e.g. KA111112',
          passportNumberHint: 'Please add a valid passport number e.g LA000044',
        },
        overseasCheck: {
          header: 'Overseas Police check(s)',
          description: `If you have lived in any other country for 12 months or more within the last 10 years and were
                        aged 16 years or over at the time, you must provide a police report from that country.`,
          livedAnywhereElse: 'Have you lived in a country other than New Zealand?',
          livedAnywhereElseDesc: `Based on the definition of living overseas provided above, have you lived in a country other
                                  than New Zealand?`,
          countryLabel: 'Which other country have you lived in?',
          uploadCheck: 'Upload a copy of your police check report',
          uploadDesc: `You will be requested to present the original police reports or send in a certified copy prior to completing your enrolment.
                       Your police report must be no more than 6 months old at the start date of your programme. We may accept older overseas reports
                       if you have not returned to that country since the date the report was issued.`,
          assistance: `For assistance on obtaining overseas police clearance, check
            <a href="${externalUrls.overSeasPoliceClearance}" target="_blank" rel="noopener">
            ${externalUrls.overSeasPoliceClearance}</a>.`,
          addCountry: 'Add Other Country',
          addCountryText:
            'Please add any other countries that you have lived in for 12 months continuously in the past 10 years.',
          removeCountryText: 'Remove other country',
          provideReport: 'Please provide a police check report.',
          provideReportDesc: 'You are required to provide an up-to-date report from that country',
        },
        countryTabHeadings: ['First Country', 'Second Country', 'Third Country', 'Fourth Country'],
      },
      academicHistory: {
        description: `NZ law requires applicants to be of a certain standard  before they can study at a NZ University.
                      There are a number of ways to gain admission to a NZ University and this section assists us in determining
                        if you meet the University admission standard. Your eligibility is based on your qualifications,
                      results, and English language proficiency.`,
        studyLevelAdditionalText: {
          [QUALIFICATION_LEVELS.SECONDARY]:
            'e.g. NCEA, Bursary, International Baccalaureate Diploma, Cambridge International Examinations',
          [QUALIFICATION_LEVELS.FOUNDATION]: 'e.g. UC CUP, UCIC',
          [QUALIFICATION_LEVELS.UNDER_GRADUATE]: 'e.g. Diploma, Certificate, Bachelors degree up to level 7',
          [QUALIFICATION_LEVELS.POST_GRADUATE]: 'e.g. Honours, Masters, PhD level 8 and above',
        },
        highestStudyLevelQuestion:
          'What is your highest level of study, or the qualification you are currently working towards?',
      },
      additionalInformation: {
        title: 'Additional Information',
        subHeading: 'Upload a copy of the additional information required for your qualification.',
        guidanceTextStart:
          'The qualification you are applying for requires some additional information. Download a copy of the required documentation for ',
        guidanceTextEnd: ' then upload the completed document below.',
        separator: ', ',
      },
      qualification: {
        header: 'Select Qualification',
        coaHeader: 'Change study options',
        returningHeader: 'Review study options',
        description: 'Select your qualification choice below.',
        willCompleteAward: 'Do you expect to complete any UC qualification this year?',
        qualTooltipOne: `If you are undecided as to what your first Teacher Education qualification preference is, you
                        should select a second qualification choice. If you wish to select a non-Teacher Education Programme as an
                        alternative choice, complete your first choice Teacher Education qualification application here and then get
                        in touch with the Contact Centre who will help you to lodge a separate application for your alternative choice`,
        teachingDesc: `If you’re considering a non-Teacher Education Programme as an alternative choice, complete your Teacher Education
                        application, then ${contactCentre}`,
        qualTooltipTwo: `For further detail regarding teaching subject selection please refer to the Required preparation section on
                        the <a href="${externalUrls.ucTeachingQualification}" target="_blank" rel="noopener">Secondary teacher education</a> web page.`,
        continueQualification: (year) =>
          `Will you be continuing your study of the below qualification(s) and subject options in ${year} or would you like to change your study options?`,
        forceContinueDescription:
          'You have previously selected these qualification(s) but you need to provide some additional information in order to continue.',
        deaggregatedInfo:
          'You were previously enrolled in a double degree. This is now captured as two degrees. Check that your qualification and subject options are displaying correctly below. If something doesn’t look right, choose <strong>‘Change study options’</strong> and make the necessary changes.',
        awardEnrolmentError: `<p>We are having trouble retrieving your qualification details. Please select your qualification and study options below.</p>
                              <p>For assistance, ${contactCentre}</p>`,
        warningModals: {
          qualChange: {
            header: 'Change Qualification',
            body: `By choosing this option you’ll be able to make changes to your qualification selection.
                  If you proceed, your existing qualification and courses will be withdrawn.
                  This change will not be processed until you have completed all steps and submitted your change.`,
            ok: 'Change qualification',
          },
          subjectChange: {
            header: 'Change Subjects',
            body: 'By choosing this option you’ll only be able to make changes to your subject choices. This change will not be processed until you have submitted your change.',
            ok: 'Change subjects',
          },
        },
        noChangesModal: {
          body: `You have not made any changes to your qualification / subject options.
                Review your options and make the necessary changes or select Back to Dashboard to cancel the Change of Application process.`,
          header: 'No changes made',
          ok: 'Ok',
        },
        changeGuidance:
          'To change your qualification, or to add/remove a concurrent qualification, select Change qualification. To keep your existing qualification and change your subject options only, select Change subjects.',
      },
      qualificationSelector: {
        qualFilterLabel: 'Select type of qualification',
        saveQual: 'Save Qualification',
        cancel: 'Cancel',
        qualLabel: 'Select a qualification:',
        selectMajor: 'Please select your majors:',
        subMajor: 'You can add up to 2 majors.',
        majorLabel: ['First', 'Second'],
        addMajor: 'ADD ANOTHER',
        major: 'Major',
        minor: 'Minor',
        selectMinor: 'Please select your minors:',
        subMinor: 'You can add up to 4 minors',
        minorLabel: ['First', 'Second', 'Third', 'Fourth'],
        addMinor: 'ADD ANOTHER',
        saveQualifications: 'Save Qualifications',
        postgradLabel: 'Postgraduate',
        undergradLabel: 'Undergraduate and graduate',
      },
      declaration: {
        applicationDeclaration: {
          html: informationHtml,
          header: '<b>I agree to this declaration</b>',
          banner: `The following declaration outlines how we manage your information. If you choose not to agree you won’t
                be able to continue with your application.`,
          infoHeader: 'Application Submission',
        },
        cacDeclaration: {
          html: cacHtml,
          header: 'Professional Conduct Declaration',
        },
        policeDeclaration: {
          html: policeRecordHtml,
          header: 'Police Record Declaration',
        },
        enrolmentChangeDeclaration: {
          html: enrolmentChangeHtml,
          header: 'Change of Enrolment Declaration Information',
        },
        starEnrolmentChangeDeclaration: {
          html: starEnrolmentChangeHtml,
          header: 'Change of Enrolment Declaration Information',
        },
        starDeclaration: {
          html: starDeclarationHtml,
          header: '<b>I agree to this declaration</b>',
        },
        microCredentialsDeclaration: {
          html: microCredentialsDeclarationHtml,
          header: '<b>I agree to this declaration</b>',
        },
        agreeToDeclarations: 'I agree to the above declarations:',
        agreeToDeclaration: 'I agree to the above declaration:',
      },
      aesAdmission: {
        funding: 'Funding',
        fundingDescription: [
          '<p>How will you be funding your study at UC.',
          `This application doesn’t include a UC scholarship and you must apply for one separately. You can find out
           more information at
           <a class="infoLink" href="${externalUrls.ucScholarships}" target="_blank" rel="noopener" style="color: black;">
             Scholarships at UC
           </a>.</p>
           <p>If you’re relying on scholarship funding, we’ll require scholarship confirmation to complete enrolment.</p>`,
        ],
        scholarship: `UC Scholarships must be applied for separately.
          Completion of this form does not mean you have applied for a scholarship.
          Confirmation of your scholarship is required prior to your enrolment being completed.`,
        aesFunding: {
          label: 'How will you be paying for your study?',
          homeScholarshipLabel: 'Who is the provider of the scholarship from your home country?',
          otherFundingLabel: 'How are you going to fund your studies here?',
        },
        admissionHeader: 'Admission Eligibility',
        admissionContext: `Depending on the nature of your application or the qualification you plan to study, you may need to provide
                          us with additional documentation or meet specific additional entry requirements.
                          Check your eligibility with <a href="${externalUrls.ucCWYN}" target="_blank"
                          rel="noopener">Check What You Need</a>.`,
      },
      ibAdmission: {
        sittingQuestion: 'Will you be sitting or have you sat your International Baccalaureate exams in NZ?',
        releaseQuestion: `Have you asked the International Baccalaureate organisation (IBO)
          to release your results to the University of Canterbury?`,
        releaseRequest: 'Have you asked the IBO to release your results to the University of Canterbury?',
        releaseBanner: 'You can not be granted University Admission until these results are received.',
      },
      cieAdmission: {
        sittingQuestion: 'Will you be sitting or have you sat your Cambridge International Exams in NZ?',
        centre: 'What is your centre number?',
        candidate: 'What is your candidate number?',
        fourDigit: 'This is a four digit number.',
      },
      englishProficiency: {
        moreInfoLinkText: `<a href="${externalUrls.ucInternationalEnglish}" target="_blank" rel="noopener">English language requirements</a>`,
        toGainAdmission: `To study here, you need to have sufficient fluency in written and spoken English. You must provide
                          evidence of your English skills. Check our
                          <a href="${externalUrls.ucInternationalEnglish}" target="_blank" rel="noopener">English language requirements</a>.`,
        disabledDueToVerificationMessage: `
          <p>These verified English Language Proficiency test results cannot be edited at this time.</p>
          <p>If you have further English Language Proficiency test results to add, please <a href="${internalUrls.documents}">upload these to
             your document library</a> and let us know by emailing <a href="mailto:admission@canterbury.ac.nz">admission@canterbury.ac.nz</a>.</p>
        `,
      },
      teachingApplication: {
        fitnessToTeach: {
          title: 'Fitness to Teach',
          teachDescription: `This information is required by the
          <a target="_blank" rel="noopener" href="${externalUrls.teachingcouncil}">Teaching Council New Zealand</a>.`,
          disabilityQuestion: 'Do you have an impairment, disability or long term medical condition(s)?',
          disabilityMinorText: `<p>This information is used to guide our outreach support, generate reports for government education agencies, and secure funding for students with disabilities. Providing this information does not impact your enrolment, visa, grades or fees.</p>
          <p>Student success and learner experience is important to UC, informing the University about your accessibility needs means we can provide further support to you. Engaging further with support services is your choice.</p>
          <p>Find out more about <a target="_blank" rel="noopener" href="${externalUrls.studentAccessibilityService}">Te Ratonga Whaikaha | Student Accessibility Service</a> on our website.</p>`,
          selectDisabilityLabel: 'Please select from the following list',
          furtherDetail: 'Please provide further detail',
          optionalFurtherDetail: 'If you’d like to provide us with further information, enter it below:',
          furtherDetailTooltip: `Ensure that you include detail of:
                                 <ul>
                                  <li>severity of the condition (including if it is short term or ongoing.</li>
                                  <li>progress made towards recovery (if applicable)</li>
                                  <li>expected timeframe for full recovery (if applicable)</li>
                                  <li>how this might enhance your teaching</li>
                                  <li>how this might affect your teaching</li>
                                 </ul>`,
          medicalCertificateMinorText:
            'You may be requested to supply a medical certificate. We will let you know if we require this.',
        },
        personalStatement: {
          personalStatementQuestion: 'Personal Statement',
          max500: 'Maximum 500 words.',
          max300: 'Maximum 300 words.',
          personalStatementMinorText: `The following information is required as part of our obligations under the Children’s Act 2014.
                                      If you have a CV or resume, you can cut and paste the information into the following boxes.`,
          personalStatementTooltip: `<p>
                                      Further detail about your personal statement can be found
                                      <a target="_blank" rel="noopener" href="${externalUrls.ucGuideToApplying}">here</a>
                                     </p>
                                     <p>
                                      We will lead you through the personal statement via a series of questions. Each box can accept
                                      up to 500 words but do not feel that you have to use them all if you can convey what you need in less.
                                     </p>`,
          experienceQuestion: 'What work and community experience do you have?',
          lastFiveYears: `Include any experience working with children and young adults, leadership roles, and community group
                          involvement over the last 5 years`,
          workType: 'Note that these can be paid, voluntary or work experience roles.',
          relevantQualifications: 'Do you have any other relevant qualifications?',
          qualificationExamples: 'For example, first aid certificates, coaching qualification, or music grades.',
        },
        confidentialSchoolReport: {
          title: 'Confidential school report',
          description: [
            `If you are still completing or have just completed secondary school then you need
             to have a Confidential School Report completed by your Principal, Careers Advisor or Dean.`,
            `This report must be completed and submitted electronically.
             Refer to
             <a target="_blank" rel="noopener" href="${externalUrls.ucGuideToApplying}">Online referee reports</a>.
             `,
          ],
        },
        associatedPerson: {
          name: 'Referee name',
          relationship: 'Role/relationship',
          email: 'Email address',
        },
      },
      postgradIntentions: {
        goalsAspirationsTitle: 'Study Goals and Career Aspirations',
        cvTitle: 'Curriculum Vitae (CV)',
        goalsAspirationsDescription: `As you’ve selected to study at a graduate or postgraduate level, we’d like to ensure
        that we have the right qualification for you.`,
        whyIsItInteresting: 'What interests you about the qualification you’re applying for?',
        whatWillYouDoNext: 'What do you plan to do after you finish this qualification?',
        uploadQuestion: 'Upload your CV',
        cvProofExplain: `Your CV (also known as a resume or biodata document) tells us
        important information about you including any previous experience, education,
        research or publications. This will help us to offer you a programme which aligns
        with your experience and future aspirational goals.
        `,
        cvRequestText: 'Please provide us with a copy of your CV',
        thesisHeader: 'Potential research supervisor (thesis only)',
        doYouHaveASupervisorInfo: `Your Supervisor is someone who will work closely with you for the period of your research. Visit
         <a href="${externalUrls.ucProfile}" target="_blank" rel="noopener">Welcome to UC Research Profile</a> to find a potential supervisor.`,
        doYouHaveASupervisor: 'If you have already contacted a potential supervisor, what is their name?',
      },
      ucswHandoff: {
        title: 'Course Selection',
        sectionHeader: 'Select Courses',
        content: `<p>You are unable to select courses for your qualification online.</p>
          <p>If you need assistance, contact the ${baseContactText(contact.enrolmentEmailAddress)}</p>`,
      },
      resolveOffer: {
        sectionHeader: 'Accept or Decline Enrolment Agreement',
        content: `
          <p>You can download a copy of your Enrolment Agreement below or from your myUC document library. If you can’t see your Enrolment Agreement there, try again later.</p>
          <p>Make sure you check all the details in your Enrolment Agreement.</p>
          <p> When you’ve reviewed your Enrolment Agreement, select the Accept or Decline Enrolment Agreement button.</p>
          <p> If you want to change your qualifications or courses, please ${contactCentre}</p>
        `,
        messageBanner: 'Your Enrolment Agreement has not yet been generated, please come back later.',
        buttonText: 'ACCEPT OR DECLINE ENROLMENT AGREEMENT',
        enrolmentAgreement: 'Enrolment Agreement',
      },
      resolveChangeOfEnrolment: {
        sectionHeader: 'Updating student details',
        content: `<p>We’re updating your details.</p>
          <p>Wait a moment - this won’t take long. You’ll be able to continue with your application once we’re done.</p>
          <p>${needAssistance}</p>`,
      },
      resolveAccount: {
        awaitingId: {
          sectionHeader: 'Awaiting Student ID creation',
          content: `<p>We’re creating a Student Identification number for you.</p>
            <p>You will receive an email confirming the ID number, once received you can continue with the
            application.</p>
            <p>${needAssistance}</p>`,
        },
        updatingDetails: {
          sectionHeader: 'Updating student details',
          content: `<p>We’re updating your details.</p>
            <p>Wait a moment - this should only take a few minutes. You’ll be able to continue with your application once we’re done.</p>
            <p>${needAssistance}</p>`,
        },
        awaitingApplication: {
          sectionHeader: 'Awaiting Application Creation',
          content: `<p>We’re creating an application for you.</p>
            <p>It should be ready shortly, once complete you can continue with the application.</p>
            <p>${needAssistance}</p>`,
        },
      },
      financialInformation: {
        sectionHeader: 'Financial Information',
        formDescription: `<p>
                            You don’t need to make any payments as part of this application process. However, you’ll
                            need to pay your fees before you can be fully enrolled. If you’re offered a place, you’ll receive an Enrolment
                            Agreement outlining how much to pay. Your fees may include:
                            <ul>
                              <li><a target="_blank" rel="noopener" href="${externalUrls.ucStudyCosts}">Fees and funding</a></li>
                              <li><a target="_blank" rel="noopener" href="${externalUrls.ucLivingCosts}">
                              Non-tuition fees</a></li>
                              <li><a target="_blank" rel="noopener" href="${externalUrls.ucAdmissionAndEnrolment}">
                              Limited entry & special applications</a></li>
                            </ul>
                          </p>
                        `,
        sslRebate: {
          radioStudyQuestion: 'Which option best describes your study?',
          extraDescriptions: {
            'NOT APPLICABLE': `
              <strong>You’ve indicated the rebate categories don’t apply to you, therefore, you’re not eligible for a rebate.</strong>
              <p>As you don’t match any of the rebate categories, you’re not eligible for a rebate. Make sure you familiarise yourself
              with the services the
              <a href="${externalUrls.ucStudentServicesLevy}" target="_blank" rel="noopener">UC Student Services Levy</a> provides.
              </p>
              `,
            DISTANCE: `
              <p>
                Students enrolled in distance courses, who also live outside of the Student Services levy zone
                (i.e. further than 100kms from Ilam campus) during the period of enrolment.
              </p>
              <p>
                To measure the distance between Ilam campus and your home, go to
                <a href="${externalUrls.gmapsMeasureDistance}" target="_blank" rel="noopener">Google Maps</a>, locate the University of
                Canterbury Ilam campus and right click.
                Select "measure distance", then click on your address on the map.
              </p>
              `,
            EDUCATION: '',
            RESEARCH: `
              <p>
                Students must be studying away from Christchurch campus in order to be eligible for this rebate.
                A rebate will be applied on a monthly basis. Students pay a minimum of 4 months’ levy.
              </p>
              `,
            'LIFETIME CAP': '',
            '30 POINTS OR LESS': `
              <p>
              You will automatically be considered for a rebate once your enrolment is complete.
              </p>
              `,
            REGIONAL: `
              <p>
                Students studying at one of the regional campuses (Nelson, Rotorua, New Plymouth)
                are automatically eligible for a partial fees rebate.
              </p>
              `,
            'OTHER REBATE': '',
          },
          header: 'Student Services Levy Rebate',
          description: `
          <p>All New Zealand universities charge an annual student services levy to provide for various student services on campus
          - they’re there to make your student experience all that it can be, so make the most of them. The Student Services Levy has a
          lifetime cap equivalent to five years of full Levy payment (e.g. five years of study at current year annual rate). This cap
          won’t apply until you’ve paid the equivalent of five years of full payment since 2010. See the
          <a href="${externalUrls.ucStudentServicesLevyPolicy}"
          target="_blank" rel="noopener">
            Student Services Levy Policy (PDF, 208KB)</a>
          for further information.</p>

          <p>Some students may be eligible for a rebate for part of the levy.
          To help us determine your eligibility, please answer the following question.</p>

          <p>We will notify you of any rebate decision via email, and where applicable, UC Financial Services will contact you with details
          on how to obtain your refund. If you authorised payment of your Student Services Levy from your Student Loan, any refund must be
          paid back to your account at Studylink.</p>

          <p>For more information, please visit
          <a href="${externalUrls.ucStudentServicesLevy}" target="_blank" rel="noopener">
          UC Student Services Levy</a>.</p>
          `,
          researchSection: {
            header: 'Supporting information from your supervisor of studies',
            inputLabel: 'SSL rebate supporting information',
            context: `
              <p>
                As a postgraduate student studying away from Christchurch you’ll be eligible for a rebate, but you
                need to provide a letter from your supervisor which includes:
              </p>
              <ul>
                <li>Confirmation of your study location and residential address</li>
                <li>Confirmation of the period you will be studying away from the Christchurch campus</li>
                <li>Your supervisor’s name and contact telephone number</li>
              </ul> <br>
              <p>Please upload your letter from your Supervisor</p>`,
          },
          educationSection: {
            header: 'Supporting information from your employer',
            inputLabel: 'SSL rebate supporting information',
            context: `
              <p>As a College of Education, Health and Human Development part-time student working as an educator you’ll be
              eligible for a rebate, but you need to provide us with a letter addressing the following:</p>
              <ul>
                <li>Employer’s letterhead including name and telephone number</li>
                <li>Your job title</li>
                <li>Confirmation that you work as an educator for 12.5 hours or more per week.</li>
              </ul><br>
              <p>Please upload your letter from your employer</p>`,
          },
          distanceSection: {
            inputLabel: 'I agree to the Distance Student Declaration',
            context: `
              <p>As a distance student, you may be eligible for a rebate, but you’ll need to agree to the Distance Student Declaration.</p>

              <p><strong>Distance Student Declaration</strong></p>

              <p>I declare that:</p>
              <ul>
                <li>I will be residing at an address more than 100km from the Ilam Campus (Matariki Building) during my studies at UC.</li>
                <li>This address will be recorded as my current address.</li>
                <li>I will be studying a distance (site code D) programme of study.</li>
                <li>I will provide such evidence as the University of Canterbury may request to support my claim.</li>
                <li>I understand false representation of my place of residence could have serious consequences,
                including cancellation of my enrolment at the University of Canterbury.</li>
              </ul>
            `,
          },
          otherRebateSection: {
            header: 'Other rebate category',
            inputLabel: '',
            context: `
            <p>
              If you believe that you’re eligible for another rebate category refer to the
              <a href="${externalUrls.ucStudentServicesLevyPolicy}"
              target="_blank" rel="noopener">Student Services Levy Policy (PDF, 208KB)</a>
              and let us know which part of the policy the rebate falls under.
            </p>`,
          },
        },
      },
      cup: {
        questionsHeader: 'CUP Questions',
        postCup: 'What do you plan to study after completing CUP?',
        postCupMinorText: 'Include the name of the qualification and your subject area.',
        howDidYouHear: 'How did you hear about CUP?',
        personalStatementHeader: 'Personal Statement',
        personalStatementDescription: `This gives you the opportunity to tell us more about yourself. Some things you could
                                      include are your background, work experience, achievements, current interests and/or future plans.
                                      You don’t need to tell us about your academic history in this section.`,
        maxWords: 'Maximum 250 words.',
      },
      courseSelectionOverview: {
        missingCoursesError: (missingCourses) =>
          `We were unable to retrieve course information for some of your selected courses, ${missingCourses}. ${needAssistance}`,
        noEnrolments: html`You have no enrolments currently. If you need to add a new enrolment, please select
          <strong>Change qualification / subjects</strong> above.`,
        warningModal: {
          title: 'You have not selected any courses',
          body: html`
            <p>Please contact us if you need assistance choosing courses or planning your programme of study:</p>
            <ul>
              <li>
                If you need assistance choosing courses or planning your programme of study, please
                <a
                  href="${externalUrls.academicAdvisors}"
                  target="_blank"
                  >contact the appropriate Student Advisor</a
                >
              </li>
              <li>
                To view what your timetable may look like, please insert courses into the
                <a
                  href="${externalUrls.timetablePlanner}"
                  target="_blank"
                  >mock timetable planner</a
                >
              </li>
              <li>For general assistance with your application or enrolment, please ${contactCentre}</li>
            </ul>
            <p>If you wish to return later to select courses and complete this application, select ‘Cancel’.</p>
            <p>Otherwise, select ‘Continue’ to submit your application</p>
          `,
          saveText: 'Continue',
          closeText: 'Cancel',
        },
        unsavedModal: {
          default: {
            question: html`
              <p>You have unsaved changes on your application.</p>
              <p>
                If you wish to return later to complete this application or to save your exemption reasons update,
                select ‘Continue’.
              </p>
              <p>
                If you have chosen your courses and are ready to submit your application, select ‘Go to declaration’.
              </p>
              <p>Otherwise, select ‘Cancel’ to close this message.</p>
            `,
            saveText: 'Go to declaration',
            customButtonText: 'Continue',
            closeText: 'Cancel',
          },
          coe: {
            question: html`
              <p>You have unsaved changes on your change of enrolment request.</p>
              <p>If you wish to return later to complete your request, select Continue.</p>
              <p>If you have completed your changes and are ready to submit your request, select Confirm changes.</p>
              <p>Otherwise select Cancel to close this message.</p>
            `,
            saveText: 'Confirm changes',
            customButtonText: 'Continue',
            closeText: 'Cancel',
          },
        },
        coeCourseChange:
          'Make your course changes and check your proposed new workload and the prerequisites of your requested changes on the page below.',
        submitCoursesRequiredNotice: html`Once you have <strong>selected your courses</strong> you will then need to
          <strong>submit courses</strong> for assessment at the end of this stage.`,
        submitChangeOfEnrolmentRequiredNotice: html`Once you have <strong>selected your courses</strong> you will then
          need to <strong>submit change of enrolment</strong> for assessment at the end of this stage.`,
        inactiveCourses: 'Inactive course selections',
      },
      finaliseEnrolment: {
        copyAddressButton: 'Copy Permanent Address',
        permanentAddress: {
          header: 'Permanent Address',
        },
        studyAddress: {
          header: 'Study Address',
          subHeader: 'Complete this section if you know where you’re staying whilst studying at UC.',
        },
        ethnicityHeader: 'Ethnicity',
        UcsaMembership: {
          header: 'UCSA Membership',
          subHeader: `For information on the UCSA, visit the
            <a href="${externalUrls.UCSA}" target="_blank" rel="noopener">UCSA website</a>`,
          body: [
            'The University of Canterbury Students’ Association (UCSA) is a not for profit organisation which provides a range of commercial and non-commercial services for students. With the vision of “student success and belonging” the Association provides Advocacy and Welfare, a subsidised Dental Scheme, support for the 170 + clubs on campus, delivery of major events, awards and celebrations as well as media platforms including CANTA. It also operates a number of cafes and bars on campus as well as two childcare centres. The UCSA is based in Haere-roa.',
            '<strong>Membership is free, and is automatically granted to all enrolled students.</strong>',
            'If you choose to opt out of the UCSA, contact UCSA: <a href="mailto:president@ucsa.org.nz">president@ucsa.org.nz</a>',
          ],
        },
      },
      coursesClosed: {
        sectionHeader: 'Courses not yet open',
        content: (
          year,
        ) => `<p>To complete your application you need to return here and select your courses when courses open in October ${year}.</p>
          <p>${needAssistance}</p>`,
        internationalContent: (
          year,
        ) => `<p>Your application has been submitted. Courses are not yet open for ${year}.</p>
          <p>${needAssistance}</p>`,
      },
      confirmChanges: {
        messageBanner:
          '<p>This application will not be processed until you have completed all tasks and submitted your request. To change your details go back to the Change Qualification/Subjects or Change Courses tasks.</p>',
        checklist: `<p>Your Change of Enrolment will be subject to academic approval and the University Policy. The time period for processing will be dependent on:
        <ul class="red-list">
          <li>Prerequisite checks</li>
          <li>Acceptable workload</li>
          <li>Release of final grades</li>
          <li>Availability of places in limited entry courses (acknowledgement does not imply course approval)</li>
        </ul>
        You will <strong>request</strong> the following changes:</p>`,
        sectionHeader: 'Summary of changes',
        noChangesBanner: 'You have not made any changes to your enrolment.',
        noChanges: 'No changes made to your enrolment.',
        coeErrorModal: {
          header: 'Change of Enrolment Error',
          body: `There is a problem with your change of enrolment. Please ${contactCentre}`,
          ok: 'Close',
        },
      },
      completeStage: {
        processStrings: {
          default: {
            stage1: {
              messageBanner: (btn) =>
                `By selecting <strong>${btn}</strong> you will not be able to go back and make any changes to this stage.`,
              infoText: (options) => ({
                complete:
                  options.isInternational && options.process === PROCESS_NAMES.NEW_STUDENT
                    ? endOfStageBase.internationalInfo + endOfStageBase.needAssistance
                    : endOfStageBase.needAssistance,
                incomplete: endOfStageBase.incompleteStage('application') + endOfStageBase.needAssistance,
              }),
            },
            stage2: {
              messageBanner: (btn) =>
                `By selecting <strong>${btn}</strong> you will not be able to go back and make any changes to this stage.`,
              infoText: () => ({
                complete: endOfStageBase.needAssistance,
                incomplete: endOfStageBase.incompleteStage('application') + endOfStageBase.needAssistance,
              }),
            },
            stage3: {
              messageBanner: () => `Submit your course selection for assessment.`,
              infoText: () => ({
                complete: endOfStageBase.canMakeCourseChanges + endOfStageBase.needAssistanceForSubmitCourses,
                incomplete: endOfStageBase.incompleteStage('application') + endOfStageBase.needAssistance,
              }),
            },
          },
          [PROCESS_NAMES.COE]: {
            stage1: {
              messageBanner: () =>
                'This application will not be processed until you have completed all tasks and submitted your request. By selecting <strong>Submit change of enrolment</strong> you are confirming submission of your Change of Enrolment request.',
              infoText: () => ({
                complete: `<p>Once submitted, you will not be able to make any changes or cancel your request.</p><p>For assistance, ${contactCentre}</p>`,
                incomplete:
                  endOfStageBase.incompleteStage('change of enrolment request') + endOfStageBase.needAssistance,
              }),
            },
          },
          [PROCESS_NAMES.OFFER_DECISION]: {
            stage1: {
              messageBanner: () =>
                'This application will not be processed until you have completed all tasks and submitted your change. By selecting Save and Submit you are confirming submission of your offer decision.',
              infoText: () => ({
                complete: `Once submitted you will not be able to change your offer decision. If you have any queries, ${internationalRelationshipsOffice}`,
                completeDoctoral: `Once submitted you will not be able to change your offer decision. If you have any queries, ${contactCentre}`,
                incomplete: `In order to submit your offer decision you need to ensure all tasks for this stage are 100% complete. Select Save and Exit if you are not ready to submit your decision. You can return to your decision when you are ready. For assistance, ${internationalRelationshipsOffice}`,
                incompleteDoctoral: `In order to submit your offer decision you need to ensure all tasks for this stage are 100% complete. Select Save and Exit if you are not ready to submit your decision. You can return to your decision when you are ready. For assistance, ${contactCentre}`,
              }),
            },
          },
          [PROCESS_NAMES.DEFER_OFFER]: {
            stage1: {
              messageBanner: () =>
                'This request will not be processed until you have completed all tasks and submitted the request. By selecting Save and Submit you are confirming submission of your request to defer.',
              infoText: () => ({
                complete: `Once submitted you will not be able to change your offer decision. If you have any queries, ${internationalRelationshipsOffice}`,
                completeDoctoral: `Once submitted you will not be able to change your offer decision. If you have any queries, ${contactCentre}`,
                incomplete: `In order to submit your deferral request you need to ensure all tasks for this stage are 100% complete. Select Save and Exit if you are not ready to submit your request. You can return to your request when you are ready. For assistance, ${internationalRelationshipsOffice}`,
                incompleteDoctoral: `In order to submit your deferral request you need to ensure all tasks for this stage are 100% complete. Select Save and Exit if you are not ready to submit your request. You can return to your request when you are ready. For assistance, ${contactCentre}`,
              }),
            },
          },
        },
        rateExperience: 'Please rate your experience',
        rateExperienceTitle: 'How happy are you with the application process so far?',
      },
      star: {
        personLabels: {
          name: 'Name',
          email: 'Email address',
        },
        secondarySchoolHeader: 'Secondary school information',
        yourStudyHeader: 'Your study at UC',
        secondarySchoolQuestion: 'What secondary school do you attend?',
        distanceQuestion: 'Are you studying some or all of your courses by distance?',
        beginStudiesQuestion: 'When do you intend to begin your studies?',
        consentFormQuestion: 'Please upload your completed consent form.',
        consentFormGuidance: `You can find this on the <a href="${externalUrls.star}" target="_blank" rel="noopener">UC website</a>.`,
        supportQuestion: 'Who is your course support person?',
        supportGuidance:
          'The subject teacher (or similar) at your school who will support you with your course if needed. You only need to provide this contact person if you are studying by distance and will not be on campus.',
        coordinatorQuestion: 'Who is your school STAR coordinator?',
        coordinatorGuidance:
          'If your school does not have a STAR coordinator, provide the name of the person who can approve your application for the STAR programme.',
        consentFormLabel: 'STAR consent form',
        nsnLabel: 'Do you know your National Student Number (NSN)?',
        nsnDescription: `This is a National Student Number or New Zealand Qualifications Authority (NZQA) number given to those who have previously studied in New Zealand.
          Your NSN is usually a 9 digit number. Do not include any zeroes at the start of the number.`,
      },
      starAcceptDecline: {
        offerGuidance: `<p>Your enrolment has been accepted.</p>
        <p>An Enrolment Confirmation letter will be sent to your email address. This letter will tell you what you need to do next.

        For assistance, ${baseContactText(contact.starEmailAddress)}</p>`,
      },
      exchangeStudyAbroad: {
        yourStudyAtUC: 'Your study at UC',
        copGuidance:
          'You will study a Certificate of Proficiency (COP) at either undergraduate or postgraduate level. You can select your choice of COP in the Qualification Selection task.',
        studyType: 'Are you applying for Study Abroad or Incoming Exchange?',
        studyTypeOptions: {
          studyAbroad: {
            title: 'Study Abroad',
            guidance:
              'Select this option if you wish to study at UC for one or more semesters as part of an overseas degree.',
          },
          incomingStudentExchange: {
            title: 'Incoming Student Exchange',
            guidance:
              'Select this option if you are currently studying at one of our exchange partner universities and have been nominated by your university to participate in an exchange.',
          },
        },
        lengthOfStudyOptions: {
          oneSemester: 'One semester',
          twoSemesters: 'Two semesters',
        },
        homeUniversity: 'What is the name of your home University?',
        majorSubject: 'What is your major?',
        studyStart: 'When do you intend to begin your studies?',
        lengthOfStudy: 'Are you intending to study at UC for one semester or two semesters?',
        studyPlan: 'Proposed study plan',
        studyPlanGuidance: `
          <p>Please indicate courses you intend to take at UC. <a href="https://www.canterbury.ac.nz/courseinfo/AdvancedSearch.aspx" target="_blank">Search by subject</a> to find your courses and add the course code and name here like this:</p>
          COSC101: Introduction to Computer Studies<br>
          HIST203: Greco-roman art
          <p>Your courses will go through an approval process. You can make changes to your course selection later. This is mandatory for postgraduate students.</p>
        `,
        transcriptUpload: 'Upload a copy of your results',
        transcriptUploadGuidance: 'Upload your Academic Transcript from your home University.',
        transcriptUploadTitle: 'Results',
        lastYearAttended: 'What year did you last attend secondary/high school?',
      },
      doctoralStudy: {
        topicAndSupervisorHeader: 'Tell us about your doctoral research topic and supervisor',
        proposedTopic: 'What is your proposed research topic?',
        departmentSchoolOrResearchCentre: 'Which Department, School or Research Centre will you be studying under?',
        contactWithSupervisor: 'Have you been in contact with a supervisor at UC?',
        supervisorName: 'Supervisor name',
        supervisorDepartment: 'Supervisor department',
        documentUploadHeader: 'Document upload',
        supervisorEvidence: `Please provide any supporting documents which you’ll rely on for your application. Check <a href="${externalUrls.postgraduateRequirements}" target="_blank" rel="noopener">Admission Requirements</a> for your qualification for more information about required documents.`,
        hasSupervisorGuidance:
          'Please provide written evidence that your proposed supervisor has indicated they are supportive of your application e.g. screenshot of an email from your supervisor. Upload these below.',
        noSupervisorGuidance: `You must contact your proposed supervisor and obtain their support before submitting this application.
          Your application won’t be processed without confirmation that you have a supervisor.
          You can save your application and come back to complete it at a later time. To identify experts in a given area,
          you can search the school or department website, or the <a href="${externalUrls.ucProfile}" target="_blank" rel="noopener">UC Research Profile</a> database.
          Please provide any supporting documents which you’ll rely on for your application.`,
        transferringHeader: 'Transferring from another degree at UC',
        transferringFromMasters: "Are you transferring from a UC Master's degree to a Doctoral degree?",
        transferringGuidance: `You will need to provide the following documents. Upload these below.
        <ul>
          <li>Doctoral Research Proposal</li>
          <li>Master’s Progress Report</li>
          <li>Letter of support from your Master’s Head of Department or School, and the Dean of the Faculty</li>
        </ul>`,
        studyOptionsHeader: 'Study options',
        supportingDocuments: 'Supporting documents',
        resideAwayFromChristchurch: 'Will you reside away from Christchurch for all or part of your study?',
        fundingHeader: 'Funding your study',
        fundingMethod: 'How do you intend to fund your study?',
        scholarshipName: 'Scholarship name',
        scholarshipProvider: 'Scholarship provider',
        scholarshipLink: `
          <p>Please check the following box if you would like to be considered for a UC Doctoral Scholarship. By ticking this box you acknowledge the following:</p>
          <p>
            <ol class="inside-list">
              <li>That you hold a qualifying degree with at least an equivalent UC GPA of 7.0</li>
              <li>That you have declared any other funding you receive from other sources and you have read the UCDS regulations for co-tenability of funding</li>
              <li>That you have included in your CV any relevant research experience, publications and all academic transcripts from qualifying degrees</li>
            </ol>
          </p>
          <p>If you have any queries please contact <a href="mailto:${contact.scholarshipEmailAddress}">${contact.scholarshipEmailAddress}</a>.</p>`,
        beginResideAway:
          'Do you intend to reside away from Christchurch at the beginning of your study and then move to Christchurch?',
        whenMoveAway: 'After what period of time do you intend to move to Christchurch?',
        timeAway:
          'Will your proposed research project require spending time away from Christchurch either within New Zealand or overseas?',
        timeAwayGuidance: `
          If your application to the doctoral qualification is approved, you will need to seek approval, and make a separate application for each period of extramural study.
          Each application will be assessed, approved/declined based on many variables, including a risk assessment of the destination.
          Please complete the <a href="${externalUrls.extramuralStudyForm}" target="_blank" rel="noopener">Extramural Study Form</a>.
          International students will incur international tuition-fee rates for periods in excess of 12 months of overseas extramural study during their Doctoral programme.`,
        declarationAgree: 'I agree to the above declaration',
        declarationLabel: 'UC Doctoral Scholarship Information',
        evidenceOfSupervisionTitle: 'Evidence of Supervision',
        evidenceOfSupervisionDescription:
          'Please provide us with evidence that your thesis supervisor has agreed to accept you as a thesis student.',
        evidenceOfSupervisionQuestion: 'Upload your evidence here',
        thesisPaperTitle: 'Thesis/Research Paper',
        thesisPaperDescription:
          'Please provide us with a copy of your thesis or other research paper that has been completed at a post-graduate level.',
        thesisPaperQuestion: 'Upload your thesis or other research paper',
      },
      phdAcceptDecline: {
        offerGuidance: `
          <p>You have already accepted your offer of place. The postgraduate office is finalising your enrolment.</p>
          <p>If you need assistance, please ${contactCentre}</p>`,
      },
      studyOptions: {
        header: 'Select your study options',
        intakesAvailableDescription: `If the qualification is closed for the intake you request, please choose another intake. If you have any queries please contact us using AskUC Live Chat; phone <a href="tel:${contact.phone.local.linkable}">${contact.phone.local.display}</a> or email <a href="mailto:${contact.enrolmentEmailAddress}">${contact.enrolmentEmailAddress}</a>.`,
        whereHowWillYouStudy: 'Where and how will you study?',
        noIntakesAvailable: `There are no more intakes for your chosen qualification this year.  Please apply for next year.  If you need further assistance, please contact us via email <a href="mailto:${contact.enrolmentEmailAddress}">${contact.enrolmentEmailAddress}</a>, phone domestic <a href="tel:${contact.phone.local.linkable}">${contact.phone.local.display}</a>; international <a href="tel:${contact.phone.international.linkable}">${contact.phone.international.display}</a> or AskUC Live Chat.`,
        selectStudyOption: (title) => `For your ${title} select your study option below.`,
        selectOtherStudyOption: (qualOccurrenceCount) => {
          const hasOccurrencesTitle = 'For your other qualifications not listed above, select your study option below.';
          const noOccurrencesTitle = 'Select your study option below';
          return qualOccurrenceCount > 0 ? hasOccurrencesTitle : noOccurrencesTitle;
        },
      },
      evidenceOfFunds: {
        title: 'Evidence of funding to support your study',
        uploadGuidanceTitle: 'Upload evidence of funding',
        uploadGuidance: `<p>In order to secure a student visa in New Zealand you must prove that you can pay for your tuition fees and costs of living.</p>
                        <p>UC does not offer full scholarships, so you need to show evidence that you have access to a minimum of NZD 40,000.</p>
                        <p>As proof of funding, please attach either:
                          <ul>
                            <li>A current bank statement showing sufficient funds for your tuition and living costs, minimum of $40,000; or</li>
                            <li>An official confirmation letter from a funding organisation that confirms your sponsorship</li>
                          </ul>
                        </p>
                        <p>To understand funding required to study at UC in New Zealand, please visit the <a href="${externalUrls.ucInternationalFees}" target="_blank" rel="noopener">International Undergraduate Fees</a> website.</p>
                        `,
        uploadLabel: 'Evidence of funding',
      },
      confirmCOAChanges: {
        infoMessage: `<p>This change will not be processed until you have completed all tasks and submitted your change.</p>
                      <p>By selecting Submit Change of Application you are confirming submission of your change.</p>`,
        assistance: needAssistance,
        noChangesMade: "You haven't made any changes to your application.",
      },
      teachingMicrocredentials: {
        [MCED_QUAL_TYPES.MCED]: {
          associatedPersonQuestion: 'Tell us about your role and the people and school(s) you work with',
          roleRelationship: {
            type: 'What is your role?',
            name: 'Who are your partner teachers and/or facilitators?',
            relationship: 'Which school do you work for? If you are a facilitator: which are your partner school(s)?',
          },
        },
        [MCED_QUAL_TYPES.ASLA]: {
          associatedPersonQuestion: 'Tell us about your role and the school you work with',
          roleRelationship: {
            type: 'What is your role?',
            name: 'Who are your partner teachers and/or facilitators?',
            relationship: 'Which school do you work for?',
          },
        },
        supportingDocumentTitle: 'Upload a copy of your supporting documentation',
        supportingDocumentType: 'Micro-credential supporting document',
        supportingDocumentHelp: `Please upload evidence of the partnership arrangement you have with your school(s) and/or facilitator.
        You will be notified if you need to provide this evidence.`,
      },
    },
    session: {
      timeRemaining: (mins) => `You will be logged out in ${mins} ${mins === 1 ? 'minute' : 'minutes'}`,
      loggingOut: 'Logging out...',
      modal: {
        header: 'Your session is about to expire',
        body: '',
        ok: 'Logout',
        cancel: 'Continue session',
      },
    },
  },
  labels: {
    appName: APP_NAMES[UserTypes.student],
    user: {
      firstName: 'First Name',
      lastName: 'Last Name',
      oneName: 'Name',
      email: 'Email Address',
      emailOrUsername: 'Email Address or UC Username (current students only)',
      password: {
        default: 'Password',
        new: 'Set a Password',
        confirm: 'Confirm Password',
        show: 'Show Password',
        hide: 'Hide Password',
      },
    },
  },
  success: {
    successfulUpdate: (field) => `Successfully updated ${field}!`,
    successfulAction: (action) => `Successfully ${action}!`,
  },
  errors: {
    invalidApplicationYear:
      'You can’t access this application. Please resume or begin an application for a valid year.',
    generic: 'A server error occurred, please try again later.',
    unknown: 'An error occurred, please try again later',
    fourOhFour: 'Could not find the requested resource',
    fourOhOne: 'Request was unauthorized',
    fourOhThree: 'Permission denied',
    fileUpload: 'There was an error uploading your file, please check the upload guidance and try again.',
    fileUploadName: 'The file name may only use the characters A to Z, 0 to 9, and simple punctuation.',
    fileTypeInvalid: 'Invalid file type, please check your file type matches our upload guidance and try again.',
    removeFileUpload: 'Error removing your file. Please try again later',
    fourOhNine: 'A resource already exists',
    fourTwentyTwo: 'The request failed validation.',
    fourSeventeen: 'The expectation failed: this person is a duplicate',
    invalidForm: 'This form is incomplete. Please fill out all required fields and try to submit again.',
    invalidDates: 'The expiry date cannot be before the start date.',
    emailsDontMatch: 'The emails should match.',
    referenceData: {
      typeMissing: 'Reference type does not exist',
      codeMissing: 'Code does not exist for reference type',
      generic: 'Reference data could not be requested, please try again later',
    },
    noCrash: {
      generic: 'A server error occurred, please try again later.',
    },
    hydrateFailed: {
      title: 'Login error',
      body: `
        <p>We’re trying to find you in our system.</p>
        <p>Please reload the page and try logging in again.</p>
        <p>If you see this message again, please ${contactCentre}</p>
      `,
    },
    firebase: {
      noUser: 'There was no user found.',
    },
    auth: {
      generic: 'Authentication error, please try again later.',
      genericFirebase: 'Authentication service error, please try again later.',
      fourOhNine: `Please ${contactCentre}, they’ll resolve this quickly for you.`,
      fourOhNineTitle: 'Beep boop! Error 409',
      invalidEmail: 'The email is not correctly formatted.',
      userNotFound: 'Email or Password is incorrect.',
      wrongPassword: 'Password is incorrect.',
      ucUserNotFound: 'UC Username or Password is incorrect.',
      socialAccountExists: 'Account already exists with another login provider, please try another login provider.',
      networkError: 'Connection error. Please disable any browser plugins/ad blockers and try again.',
      invalidAccountType:
        'Incorrect account type: you can only change your login email address if you created your account on the myUC login page.',
      typeMissing: 'Reference data type does not exist',
      passwordsDontMatch: 'The passwords should match.',
      countryBlocked: `This service is unavailable in your country. Please ${contactCentre} for further assistance.`,
      userDisabled: `This account has been disabled. Please ${contactCentre} for further assistance.`,
    },
    declaration: {
      required: 'You must agree to the declarations before you can submit',
      requiredOne: 'You must agree to the declaration before you can submit',
    },
    createAccount: {
      generic: 'Account creation failed, please try again later.',
      weakPassword: 'Password must be at least six characters.',
    },
    validation: {
      format: {
        date: 'Please enter a valid date.',
        email: 'Enter a valid email address.',
      },
    },
    validations: {
      longerThanExpected: (length) => `Expected value to be longer than ${length} characters.`,
      shorterThanExpected: (length) => `Expected value to be less than ${length} characters.`,
    },
    dates: {
      invalid: 'Please select a valid date',
      range: 'Date is not within specified range',
    },
    group: 'Please complete each field in this group.',
  },
  loading: 'Loading...',
};

if (environment.scope === 'agent') {
  strings = merge(strings, agentStrings);
}
if (environment.scope === 'staff') {
  strings = merge(strings, staffStrings);
}
if (environment.subScope === 'ucOnline') {
  strings = merge(strings, onlineStrings);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default strings as any;
